<div class="inicio-carousel-container">
    <swiper-container class="swiper swiper-container" init="false" #elemento >
        <swiper-slide>
            <div class="slide-container" style='background-image: url("./../../../../assets/img/inicio-carousel/carousel-big-7.png");'>
              <div class="first-slide-container">
                  <div class="first-slide-title-container">
                    <!-- Obtén más información y revisa tus trámites de Pérdidas Totales -->
                      <p class="first-slide-title title-bold">
                        Obtén más información
                        <mark class="white">y revisa tus trámites de Pérdidas Totales</mark>
                          <!-- <mark class="white">para todos</mark> -->
                      </p>
                      <div class="footer-borders-container">
                          <div class="container-left">
                              <div class="rombo-blue-left"></div>
                          </div>
                          <div  class="container-right">
                              <div class="rombo-blue-rigth"></div>
                          </div>
                      </div>
                  </div>
                  <button (click)="goToContactanos()" class="first-slide-button button-size">
                        Contáctanos
                  </button> 
              </div>
                  
              </div>
        </swiper-slide>
        <swiper-slide>
            <div class="slide-container" style='background-image: url("./../../../assets/img/inicio-carousel/carousel_bg.png");'>
                <div class="first-slide-container">
                    <div class="first-slide-title-container">
                        <p class="first-slide-title title-bold">Generamos confianza 
                            <mark class="white">en cada solución que ofrecemos</mark>
                        </p>
                        <div class="footer-borders-container">
                            <div class="container-left">
                                <div class="rombo-blue-left"></div>
                            </div>
                            <div  class="container-right">
                                <div class="rombo-blue-rigth"></div>
                            </div>
                        </div>
                    </div>
                    <button (click)="goToSoluciones()" class="first-slide-button button-size">
                        Soluciones
                    </button> 
                </div>
            </div>
        </swiper-slide>  
        <swiper-slide>
          <div class="slide-container" style='background-image: url("./../../../../assets/img/inicio-carousel/carousel-big-2.png");'>
            <div class="first-slide-container">
                <div class="first-slide-title-container">
                    <p class="first-slide-title title-bold">Comprendemos las necesidades tecnológicas
                        <mark class="white">de nuestros clientes como nadie más</mark>
                    </p>
                    <div class="footer-borders-container">
                        <div class="container-left">
                            <div class="rombo-blue-left"></div>
                        </div>
                        <div  class="container-right">
                            <div class="rombo-blue-rigth"></div>
                        </div>
                    </div>
                </div>
                <button (click)="goToSoluciones()" class="first-slide-button button-size">
                    Soluciones
                </button> 
            </div>
                
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="slide-container" style='background-image: url("./../../../../assets/img/inicio-carousel/carousel-big-3.png");'>
              <div class="first-slide-container">
                  <div class="first-slide-title-container">
                      <p class="first-slide-title title-bold">Rompemos barreras y redefinimos
                          <mark class="white">la tecnología para el ecosistema asegurador</mark>
                      </p>
                      <div class="footer-borders-container">
                          <div class="container-left">
                              <div class="rombo-blue-left"></div>
                          </div>
                          <div  class="container-right">
                              <div class="rombo-blue-rigth"></div>
                          </div>
                      </div>
                  </div>
                  <button (click)="goToSoluciones()" class="first-slide-button button-size">
                      Soluciones
                  </button> 
              </div>
                  
              </div>
        </swiper-slide>
        <swiper-slide>
        <div class="slide-container" style='background-image: url("./../../../../assets/img/inicio-carousel/carousel-big-4.png");'>
            <div class="first-slide-container">
                <div class="first-slide-title-container">
                    <p class="first-slide-title title-bold">Transformamos el futuro
                        <mark class="white">de la gestión del seguro con tecnología de vanguardia</mark>
                    </p>
                    <div class="footer-borders-container">
                        <div class="container-left">
                            <div class="rombo-blue-left"></div>
                        </div>
                        <div  class="container-right">
                            <div class="rombo-blue-rigth"></div>
                        </div>
                    </div>
                </div>
                <button  (click)="goToSoluciones()" class="first-slide-button button-size">
                    Soluciones
                </button> 
            </div>
                
            </div>
        </swiper-slide>
        <swiper-slide>
        <div class="slide-container" style='background-image: url("./../../../../assets/img/inicio-carousel/carousel-big-5.png");'>
            <div class="first-slide-container">
                <div class="first-slide-title-container">
                    <p class="first-slide-title title-bold">
                        Acompañamos los procesos operativos
                    <mark class="white">de nuestros clientes mediante mejores prácticas</mark>
                    </p>
                    <div class="footer-borders-container">
                        <div class="container-left">
                            <div class="rombo-blue-left"></div>
                        </div>
                        <div  class="container-right">
                            <div class="rombo-blue-rigth"></div>
                        </div>
                    </div>
                </div>
                <button (click)="goToSoluciones()" class="first-slide-button button-size">
                    Soluciones
                </button> 
            </div>
                
            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="slide-container" style='background-image: url("./../../../../assets/img/inicio-carousel/carousel-big-6.png");'>
                <div class="first-slide-container">
                    <div class="first-slide-title-container">
                        <p class="first-slide-title title-bold">
                            Brindamos soluciones tecnológicas y operativas
                        <mark class="white">con un enfoque ágil y confiable</mark>
                        </p>
                        <div class="footer-borders-container">
                            <div class="container-left">
                                <div class="rombo-blue-left"></div>
                            </div>
                            <div  class="container-right">
                                <div class="rombo-blue-rigth"></div>
                            </div>
                        </div>
                    </div>
                    <button (click)="goToSoluciones()" class="first-slide-button button-size">
                        Soluciones
                    </button> 
                </div>
            </div>
        </swiper-slide>
    </swiper-container>
    <div class="our-clients-container">
      
        <div class="row our-clients-align">
            <div class="col-12 col-lg-2">
                <span class="our-clients-title">
                    NUESTROS CLIENTES
                </span>
            </div>
            
            <div class="col-12 col-lg-10">
                <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" class="carousel">
                    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                        <img src="{{ slide.img }}" alt="" style="margin: 0 auto;">
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
      
    </div>
</div>
