import { Component, OnInit, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/services/routes/routes.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isTablet = false;
  showMenu = true;

  Breakpoints = Breakpoints;
  currentBreakpoint: string = '';

  public router = inject(Router);

  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      // tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpoint$.subscribe(() =>
      this.breakpointChanged()
    );
  }

  private breakpointChanged() {
    if (this.breakpointObserver.isMatched('(max-width:710px)')) {
      this.currentBreakpoint = '(max-width: 710px)';
      this.isTablet = true;
      this.showMenu = false;
    }
    else if (this.breakpointObserver.isMatched('(max-width:1025px)')) {
      this.currentBreakpoint = '(max-width: 1025px)';
      this.showMenu = true;
      this.isTablet = false;
    }
  }

  media = [
    {
      icon: 'assets/img/footer/yt.svg',
      url: 'https://www.youtube.com/@Teggium'
    },
    {
      icon: 'assets/img/footer/linkedin.svg',
      url: 'https://www.linkedin.com/company/teggium/'
    },
    {
      icon: 'assets/img/footer/fb.svg',
      url: 'https://www.facebook.com/Teggium/'
    },
    {
      icon: 'assets/img/footer/twitter.svg',
      url: 'https://twitter.com/teggium'
    }
  ]

  menu = [
    {
      title: 'Inicio',
      sections: [
        {
          ruta: 'inicio',
          name: 'Soluciones',
          url: 'soluciones'
        },
        {
          ruta: 'inicio',
          name: '¿Por qué nosotros?',
          url: 'socios'

        },
        {
          ruta: 'inicio',
          name: 'Indicadores',
          url: 'indicadores'
        },
        {
          ruta: 'inicio',
          name: 'Cobertura',
          url: 'cobertura'
        }
      ]
    },
    {
      title: 'Nosotros',
      sections: [
        {
          ruta: 'nosotros',
          name: 'Tecnología',
          url: 'tecnologia'
        },
        {
          ruta: 'nosotros',
          name: 'Metodología',
          url: 'metodologia'
        },
        {
          ruta: 'nosotros',
          name: 'Hub',
          url: 'hub'
        }
      ]
    },
    {
      title: 'Tecnología',
      sections: [
        {
          ruta: 'tecnologia',
          name: 'Presto',
          url: 'tecnologia'
        }
      ]
    },
    {
      title: 'Talento',
      sections: [
        {
          ruta: 'talento',
          name: 'Formulario',
          url: 'talento'
        }
      ]
    },
    {
      title: 'Actualidad',
      sections: [
        {
          ruta: 'actualidad',
          name: 'Blog',
          url: 'actualidad'
        }
      ]
    },
    {
      title: 'Contacto',
      sections: [
        {
          ruta: 'contacto',
          name: 'México',
          url: 'contacto'
        },
        {
          ruta: 'contacto',
          name: 'Colombia',
          url: 'contacto'
        },
        {
          ruta: 'contacto',
          name: 'Chile',
          url: 'contacto'
        },
        {
          ruta: 'contacto',
          name: 'Colombia',
          url: 'contacto'
        },
        {
          ruta: 'contacto',
          name: 'Perú',
          url: 'contacto'
        },
        {
          ruta: 'contacto',
          name: 'Panamá',
          url: 'contacto'
        },
        {
          ruta: 'contacto',
          name: 'Brasil',
          url: 'contacto'
        },
        {
          ruta: 'contacto',
          name: 'España',
          url: 'contacto'
        }
      ]
    }
  ]

  menu_tablet = [
    {
      title: 'Talento',
      sections: [
        {
          ruta: 'talento',
          name: 'Formulario',
          url: 'talento'
        }
      ]
    },
    {
      title: 'Actualidad',
      sections: [
        {
          ruta: 'actualidad',
          name: 'Blog',
          url: 'actualidad'
        }
      ]
    }
  ]

  public urlComponent = inject(RoutesService);

  // ScrollIntoView(elem: string) {
  //   document.querySelector(elem)!.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  // }

  selectFooter(item: any) {
    // console.log("item",item)

    if (item.ruta === 'contacto' || item.ruta === 'actualidad' || item.ruta === 'tecnologia' || item.ruta === 'talento') {

      if (this.router.url === `/${item.ruta}`) {
        document.querySelector(`#${item.url}`)!.scrollIntoView({behavior:'smooth'});
      } else {
        this.router.navigateByUrl(`/${item.ruta}`);
      }
    }

    if (item.ruta === 'inicio') {
      if (this.router.url === '/inicio') {
        document.querySelector(`#${item.url}`)!.scrollIntoView({behavior:'smooth'});
      } else {
        this.urlComponent.setComponentName(item.url);
        this.router.navigateByUrl("/inicio")
      }


    }


    if (item.ruta === 'nosotros') {
      if (item.url === 'tecnologia') {
        if (this.router.url === '/tecnologia') {
          document.querySelector(`#${item.url}`)!.scrollIntoView({behavior:'smooth'});
        } else {
          this.router.navigateByUrl('/tecnologia');
        }

      }
      else {
        this.urlComponent.setComponentName("taps");
        this.router.navigate(['/inicio'], { state: { component: 'taps' } });
      }

      if (this.router.url === '/inicio') {
        document.querySelector(`#taps`)!.scrollIntoView({behavior:'smooth'});
      }

    }

  }
}
