import { Component } from '@angular/core';

@Component({
  selector: 'app-tecnologia-plataforma',
  templateUrl: './tecnologia-plataforma.component.html',
  styleUrls: ['./tecnologia-plataforma.component.scss']
})
export class TecnologiaPlataformaComponent {

}
