import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-container',
  templateUrl: './error-container.component.html',
  styleUrls: ['./error-container.component.scss']
})
export class ErrorContainerComponent {

  constructor(private router: Router){}

  goToInicio(){
    this.router.navigate(['/inicio']);
  }
}
