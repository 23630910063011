import { Component,inject,ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/services/routes/routes.service';

@Component({
  selector: 'app-inicio-page',
  templateUrl: './inicio-page.component.html',
  styleUrls: ['./inicio-page.component.scss'],
  animations: [

  ]
})
export class InicioPageComponent  {

  @ViewChild('scrollToMe') scrollToMe?: ElementRef;

  public variable: any = null

  constructor(private router: Router) {
    this.variable = this.router.getCurrentNavigation()?.extras.state
  }

  public urlComponent = inject(RoutesService);

  ngOnInit(){
    
  }
  ngAfterViewInit(){
    setTimeout( () => {
      this.urlComponent.nameComponent.subscribe({
        next: (value: string) => {
          if(value) {
            let val = `#${value}`
              document.querySelector(val)?.scrollIntoView({behavior:'smooth'});
          }
        }
      })
    }, 100)
  }

  showPeru = false
  enter(event: any) {
    this.showPeru = true
  }

  out(event: any) {
    this.showPeru = false
  }
}
