<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<div style="position: relative;">
    <div class="sideContent" [ngClass]="isClose ? 'show' : 'hide' ">
        <div class="d-flex justify-content-between" style="padding: 20px 50px 20px 20px; border-bottom: 1px solid #d1d2d41f; align-items: center;">
            <a routerLink="inicio" (click)="closeSideBar()">
                <figure>
                    <img src="./../../../assets/img/logo-teggium.png" alt="logo">
                </figure>
            </a>
            <div class="menu-icon" (click)="closeSideBar()">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17" fill="none">
                    <rect width="22" height="2.2" fill="#6EE1EB" />
                    <rect y="7.19995" width="22" height="2.2" fill="#6EE1EB" />
                    <rect y="14.3999" width="22" height="2.2" fill="#6EE1EB" />
                </svg>
            </div>
        </div>
        <ul>
            <li>
                <a routerLink="inicio" routerLinkActive="active-link" class="h-menu-item" (click)="inicioTop(); closeSideBar()">Soluciones</a>
            </li>
            <li>
                <a routerLink="tecnologia" routerLinkActive="active-link" class="h-menu-item" (click)="closeSideBar()">Tecnología</a>
            </li>
            <li>
                <a routerLink="inicio" class="h-menu-item"  (click)="closeSideBar();ScrollIntoView('#socios')">¿Por qué nosotros?</a>
            </li>
            <li>
                <a routerLink="talento" routerLinkActive="active-link" class="h-menu-item" (click)="closeSideBar()"> Talento </a>
            </li>
            <li>
                <a routerLink="actualidad" routerLinkActive="active-link" class="h-menu-item" (click)="closeSideBar()">Actualidad</a>
            </li>
        </ul>
       
        <div style="margin: 30px 0 0 20px;">
            <button class="button-size" (click)="goContactPage(); closeSideBar()">
                Contáctanos
            </button>
        </div>
     
        <figure style="display: flex; justify-content: end; position: absolute; right: 0; bottom: 0;">
            <img src="../assets/img/Group_63.png" alt="">
        </figure>
    </div>
    
    <div>
        <app-header [valueMenu]="isClose" (closeByLogo)="closeMenuMob($event)" (openSide)="fnOpen($event)"></app-header>
        
        <router-outlet></router-outlet>
        
        <app-footer></app-footer>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading> 
</div>

