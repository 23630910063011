import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  public nameComponent: BehaviorSubject<string> = new BehaviorSubject<string>('');

  setComponentName(value: string) {
    this.nameComponent.next(value);
  }

  constructor() { }

}
