<div class="numbers-container">
    <div class="numbers-content">
        <div class="numbers-values" >
            <div class="numbers-value-container container-counter">
                <h3 class="number-value">+{{number1}}mil</h3>
                <p class="number-description">Operaciones anuales de pólizas emitidas</p>
            </div>
            <div class="numbers-value-container">
               <h3 class="number-value">
                +{{number2}}mil
               </h3> 
               <p class="number-description">Operaciones atendidas por
                cumplimiento</p>
            </div>
            <div class="numbers-value-container">
                <h3 class="number-value">+{{number3}}mil</h3>
                <p class="number-description">Siniestros anuales
                    atendidos</p>
                
            </div>
            <div class="numbers-value-container">
                <h3 class="number-value">+{{number4}}%</h3>
                <p class="number-description">Nivel de servicio</p>
                
            </div>
            <div class="numbers-value-container">
                <h3 class="number-value">+{{number5}}%</h3>
                <p class="number-description">Nivel de calidad</p>
                
            </div>
        </div>
        <div class="footer-borders-container">
            <div class="container-left">
                <div class="rombo-blue-left"></div>
            </div>
            <div  class="container-right">
                <div class="rombo-blue-rigth"></div>
            </div>
        </div>
    </div>
</div>
