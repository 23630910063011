import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner-black',
  templateUrl: './banner-black.component.html',
  styleUrls: ['./banner-black.component.scss']
})
export class BannerBlackComponent {
  public router = inject(Router)

  goContactPage() {
    this.router.navigateByUrl('/contacto');
  }

}
