import { Component,inject } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicio-taps',
  templateUrl: './inicio-taps.component.html',
  styleUrls: ['./inicio-taps.component.scss']
})
export class InicioTapsComponent {
  public router = inject(Router)

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    // console.log('index => ', tabChangeEvent.index);
  }

  goToContactanos(){
    this.router.navigateByUrl('/contacto')
  }

  goToTech(){
    this.router.navigateByUrl('/tecnologia')
  }
}
