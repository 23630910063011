import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { InicioCarouselComponent } from './components/inicio/inicio-carousel/inicio-carousel.component';
import { InicioPageComponent } from './pages/inicio-page/inicio-page.component';
import { TecnologiaPageComponent } from './pages/tecnologia-page/tecnologia-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { CountUpModule } from 'ngx-countup';
import { BannerBlackComponent } from './components/shared/banner-black/banner-black.component';
import { InicioSolutionComponent } from './components/inicio/inicio-solution/inicio-solution.component';
import { InicioPurposeComponent } from './components/inicio/inicio-purpose/inicio-purpose.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TecnologiaBannerComponent } from './components/tecnologia/tecnologia-banner/tecnologia-banner.component';
import { InicioTapsComponent } from './components/inicio/inicio-taps/inicio-taps.component';
import { InicioCoberturaComponent } from './components/inicio/inicio-cobertura/inicio-cobertura.component';
import { InicioNumbersComponent } from './components/inicio/inicio-numbers/inicio-numbers.component';
import { InicioBlogComponent } from './components/inicio/inicio-blog/inicio-blog.component';
import { InicioEstrategiaComponent } from './components/inicio/inicio-estrategia/inicio-estrategia.component';
import { TecnologiaPlataformaComponent } from './components/tecnologia/tecnologia-plataforma/tecnologia-plataforma.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { TalentoPageComponent } from './pages/talento-page/talento-page.component';
import { TalentoContainerComponent } from './components/talento/talento-container/talento-container.component';
import { ActualidadPageComponent } from './pages/actualidad-page/actualidad-page.component';
import { ActualidadContainerComponent } from './components/actualidad/actualidad-container/actualidad-container.component';
import { ContactoPageComponent } from './pages/contacto-page/contacto-page.component';
import { ContactoContainerComponent } from './components/contacto/contacto-container/contacto-container.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ErrorContainerComponent } from './components/error/error-container/error-container.component';
import { TapsComponent } from './components/inicio/inicio-taps/tap/taps/taps.component';
import { TapItemComponent } from './components/inicio/inicio-taps/tap/tap-item/tap-item.component';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { TitleStrategy } from '@angular/router';
import { DynamicTitleStrategyService } from './services/routes/dynamic-title-strategy.service';
import { InicioDescubreComponent } from './components/inicio/inicio-descubre/inicio-descubre.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    InicioCarouselComponent,
    InicioPageComponent,
    TecnologiaPageComponent,
    InicioTapsComponent,
    InicioCoberturaComponent,
    InicioSolutionComponent,
    InicioPurposeComponent,
    InicioNumbersComponent,
    InicioCoberturaComponent,
    BannerBlackComponent,
    InicioBlogComponent,
    InicioEstrategiaComponent,
    FooterComponent,
    TecnologiaBannerComponent,
    TecnologiaPlataformaComponent,
    TalentoPageComponent,
    TalentoContainerComponent,
    ActualidadPageComponent,
    ActualidadContainerComponent,
    ContactoPageComponent,
    ContactoContainerComponent,
    ErrorPageComponent,
    ErrorContainerComponent,
    TapsComponent,
    TapItemComponent,
    BlogDetailComponent,
    InicioDescubreComponent
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    CountUpModule,
    SlickCarouselModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    CarouselModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
  ],
  providers: [
    { provide: TitleStrategy, useClass: DynamicTitleStrategyService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
