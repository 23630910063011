import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-inicio-estrategia',
  templateUrl: './inicio-estrategia.component.html',
  styleUrls: ['./inicio-estrategia.component.scss']
})
export class InicioEstrategiaComponent {

  showCostoVariableInformation: boolean = false
  showDisponibilidadInformation: boolean = false
  showFlexibilidadInformation: boolean = false
  showEzpecializacionInformation: boolean = false

  customOptions: OwlOptions = {
    autoWidth: true,
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoHeight:true,
    responsive: {
      0: {
        items: 1.25,
        margin:20,
        autoHeight: false
      },
      576: {
        items: 2.25,
        margin:20,
      },
      740: {
        items: 3.15,
        margin: 20
      },
      940: {
        margin:30,
        items: 3.75
        
      }
    },
    nav: false
  }


  enterCostoVariable($event:any){
    this.showCostoVariableInformation = true
  }

  outCostoVariable($event:any){
    this.showCostoVariableInformation = false
  }

  enterDisponibilidad($event:any){
    this.showDisponibilidadInformation = true
  }

  outDisponibilidad($event:any){
    this.showDisponibilidadInformation = false
  }

  enterFlexibilidad($event:any){
    this.showFlexibilidadInformation = true
  }

  outFlexibilidad($event:any){
    this.showFlexibilidadInformation = false
  }

  enterEspecializacion($event:any){
    this.showEzpecializacionInformation = true
  }

  outEspecializacion($event:any){
    this.showEzpecializacionInformation = false
  }

}
