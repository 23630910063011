import { Component } from '@angular/core';

@Component({
  selector: 'app-actualidad-page',
  templateUrl: './actualidad-page.component.html',
  styleUrls: ['./actualidad-page.component.scss']
})
export class ActualidadPageComponent {

}
