<div class="header-container">
    <a routerLink="inicio" (click)="goInicio()"><img src="./../../../assets/img/logo-teggium.png" alt="logo"></a>
    <nav class="header-menu-container">
        <a routerLink="/inicio" routerLinkActive="active-link" class="header-menu-item" (click)="goInicio()">Soluciones</a>
        <a routerLink="/tecnologia" routerLinkActive="active-link"class="header-menu-item" >Tecnología</a>
        <a routerLink="" class="header-menu-item" (click)="ScrollPage('#socios')">¿Por qué nosotros?</a>
        <a routerLink="/talento" routerLinkActive="active-link" class="header-menu-item"> Talento </a>
        <a routerLink="/actualidad" routerLinkActive="active-link" class="header-menu-item" >Actualidad</a>
        <div class="mx-4">
            <button class=" button-size" (click)="goContactPage()">
                Contáctanos
            </button>
        </div>
    </nav>


    <div class="menu-icon" (click)="openSideBar()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17" fill="none">
            <rect width="22" height="2.2" fill="#6EE1EB"/>
            <rect y="7.19995" width="22" height="2.2" fill="#6EE1EB"/>
            <rect y="14.3999" width="22" height="2.2" fill="#6EE1EB"/>
        </svg>
    </div>
</div>
