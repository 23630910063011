<div class="contacto-container">
  <img class="contacto-lines d-none d-sm-block" src="./../../../../assets/img/talento/talento-lines.png" alt="">
  <div class="contacto-content">
    <div class="contacto-title-container">
      <div class="contacto-content-info-border">
        <p class="contacto-banner-title title-bold">Ponte en contacto con nosotros
        </p>
        <div class="footer-borders-container">
          <div class="container-left">
            <div class="rombo-blue-left"></div>
          </div>
          <div class="container-right">
            <div class="rombo-blue-rigth"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="marcas-container">
      <p class="marcas-title">MARCAS QUE YA CONFÍAN EN NOSOTROS</p>
      <div class="marcas-content">
        <img class="img-axa" src="./../../../../assets/img/contacto/axa.png" alt="">
        <img class="img-sura" src="./../../../../assets/img/contacto/sura-g.png" alt="">
        <img class="img-gnp" src="./../../../../assets/img/contacto/gnp.png" alt="">
        <img class="img-metlife" src="./../../../../assets/img/contacto/metlife.png" alt="">
      </div>
      <div class="marcas-content">
        <img class="img-afirme" src="./../../../../assets/img/contacto/afirme.png" alt="">
        <img class="img-march" src="./../../../../assets/img/contacto/march.png" alt="">
        <img class="img-gseguros" src="./../../../../assets/img/contacto/gseguros.png" alt="">
        <img class="img-hdi" src="./../../../../assets/img/contacto/hdi.png" alt="">
      </div>
    </div>

    <div class="contactos-data-container">
      <div class="item">
        <p class="name-country">México</p>
        <p class="name-contact">Daniela Lagunas Alvarez</p>
        <div class="phone-container">
          <strong class="phone-strong">C:</strong>
          <div>
            <p class="number-country">clagunas@teggium.com</p>
          </div>
        </div>
        <!-- <div class="phone-container">
                        <strong  class="phone-strong" >T:</strong>
                        <div>
                            <p class="number-country">(+52) 55 1116 2771</p>
                        </div>
                    </div> -->
        <div style="display: flex;font-size: 12px;">
          <strong class="phone-strong">D:</strong>
          <P class="address-country">
            Periférico Sur 3325, CDMX, México</P>
        </div>
        <div style="display: flex;font-size: 12px;">
          <strong class="phone-strong">D:</strong>
          <P class="address-country">
            Av. Peñuelas 18, Santiago de Querétaro, Queretaro, México</P>
        </div>
      </div>
      <div class="item">
        <p class="name-country">España</p>
        <p class="name-contact">Abel Martínez Murillo</p>
        <div class="phone-container">
          <strong class="phone-strong">C:</strong>
          <div>
            <p class="number-country">amartinezm@teggium.com</p>
          </div>
        </div>
        <div class="phone-container">
          <strong class="phone-strong">T:</strong>
          <div>
            <p class="number-country"> (+34) 646 008 379</p>
          </div>
        </div>
        <div style="display: flex;font-size: 12px;">
          <strong class="phone-strong">D:</strong>
          <P class="address-country">
            C/ Miguel Yuste, 26. Madrid, España</P>
        </div>
      </div>
      <div class="item">
        <p class="name-country">Colombia y Panamá</p>
        <p class="name-contact">Leonardo Ramirez Niño</p>
        <div class="phone-container">
          <strong class="phone-strong">C:</strong>
          <div>
            <p class="number-country">leonardo.ramirez@teggium.com</p>
          </div>
        </div>
        <div class="phone-container">
          <strong class="phone-strong">T:</strong>
          <div>
            <p class="number-country">(+57) 316 624 4816</p>
          </div>
        </div>
        <div style="display: flex;font-size: 12px;">
          <strong class="phone-strong">D:</strong>
          <P class="address-country">
            Cra. 50 #93A-29, Rionegro, Bogotá, Colombia</P>
        </div>
      </div>
      <div class="item">
        <p class="name-country">Chile y Perú</p>
        <p class="name-contact">Francisco Castillo Stack</p>
        <div class="phone-container">
          <strong class="phone-strong">C:</strong>
          <div>
            <p class="number-country">fcastillo@teggium.com</p>
          </div>
        </div>
        <div class="phone-container">
          <strong class="phone-strong">T:</strong>
          <div>
            <p class="number-country"> (+56) 9 9579 8200</p>
          </div>
        </div>
        <div style="display: flex;font-size: 12px;">
          <strong class="phone-strong">D:</strong>
          <P class="address-country">
            Miraflores 383 Piso 13, Santiago de Chile, Chile</P>
        </div>
      </div>
      <div class="item">
        <p class="name-country">Brasil</p>
        <p class="name-contact">Paulo Roberto Carvalho</p>
        <div class="phone-container">
          <strong class="phone-strong">C:</strong>
          <div>
            <p class="number-country">pcarvalho@teggium.com</p>
          </div>
        </div>
        <div class="phone-container">
          <strong class="phone-strong">T:</strong>
          <div>
            <p class="number-country"> (+55) 19 99837-2856</p>
          </div>
        </div>
        <div style="display: flex;font-size: 12px;">
          <strong class="phone-strong">D:</strong>
          <P class="address-country">
            Rua Gomes de Carvalho, 1507, Bloco A/B – Vila Olimpia –São Paulo-SP</P>
        </div>
      </div>

      <div class="item" style="background-color: #6EE1EB;">
        <p class="name-country" style="position: relative;">Siniestros: Pérdidas Totales <img
            src="./../../../../assets/img/contacto/mexico-contacto.png" alt=""
            style="height: 40px;width: 40px; position: absolute; right: 0px; top: -20px;"></p>
        <div style="display: flex;" class="item-detail">
          <strong class="phone-strong">C: 55 9344 0005</strong>
          <p>|</p>
          <p class="number-country">General de Seguros</p>
        </div>

        <div style="display: flex;" class="item-detail">
          <strong class="phone-strong">C: 55 9344 0007</strong>
          <p>|</p>
          <p class="number-country">GNP Seguros</p>
        </div>

        <div style="display: flex;" class="item-detail">
          <strong class="phone-strong">C: 55 9344 0008</strong>
          <p>|</p>
          <p class="number-country">AXA Seguros</p>
        </div>

        <div style="display: flex;" class="item-detail">
          <strong class="phone-strong">C: 55 9344 0009</strong>
          <p>|</p>
          <p class="number-country">HDI Seguros</p>
        </div>

        <div style="display: flex;" class="item-detail">
          <strong class="phone-strong" style="padding-right: 14.5px;">C: 81 3348 4019 </strong>
          <p>|</p>
          <p class="number-country">AFIRME Seguros</p>
        </div>
        <div style="display: flex;" class="item-detail">
          <strong class="phone-strong" style="padding-right: 12.5px;">C: 55 9417 6389 </strong>
          <p>|</p>
          <p class="number-country">MAPFRE Seguros</p>
        </div>
      </div>

    </div>
  </div>

</div>
