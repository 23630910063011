<container-element [ngSwitch]="numberBlog">
    <some-element *ngSwitchCase="1">
        <div class="detalle-blog-container">
            <div class="detalle-blog-content">
                <div class="detalle-blog-return" (click)="returnBlogs()">
                    <img src="./../../../../assets/img/arow-return.png" alt="">
                    <span>Volver al blog</span>
                </div>
    
                <p class="small_team_card_label">TECNOLOGÍA</p>
                <p class="detail-title title-bold">El impacto de las tendencias tecnológicas en el éxito de las
                    aseguradoras</p>
                <div class="small_team_card_detail">
                    <img src="../../../assets/img/iso_bg.png" alt="">
                    <p>EQUIPO TEGGIUM - 15 SEPT, 2023</p>
                </div>
                <img class="detail-blog-img" src="./../../../../assets/img/detail-blog-img.png" alt="">
    
                <div class="detalle-blog-info-container">
                    <div class="detalle-blog-info-content">
                        <p class="parrafo-normal">En el competitivo mundo de las aseguradoras en Iberoamérica, la adopción de tecnologías
                            emergentes se ha convertido en un factor crucial para mantenerse a la vanguardia y lograr el
                            éxito.</p>
                        <p class="parrafo-normal">
                            En este artículo, exploraremos cómo las tendencias tecnológicas están cambiando la industria de
                            seguros en la región y cómo las empresas aseguradoras y brokers pueden aprovechar estas
                            innovaciones para mejorar sus operaciones y satisfacer las demandas cambiantes de los clientes.
                        </p>
    
                        <h3 class="subtitle-parrafo-blog"><strong>1. </strong>Inteligencia Artificial (IA) y Aprendizaje Automático (AA)</h3>
    
                        <p class="parrafo-normal"> La IA y el AA están revolucionando la forma en que las aseguradoras evalúan riesgos, procesan reclamaciones y personalizan las pólizas.</p>
    
                        <h3 class="parrafo-comillas">"La IA nos permite analizar grandes volúmenes de datos para predecir tendencias y comportamientos del cliente, lo que conduce a una mejor toma de decisiones y una mayor eficiencia operativa"</h3>
                        <p class="title-parrafo-footer">John Doe, CEO de <mark>AseguraTech</mark></p>
    
                        <h3 class="subtitle-parrafo-blog"><strong>2. </strong> Internet de las Cosas (IoT) y Telemetría</h3>
    
                        <p class="parrafo-normal">La IoT y la telemetría permiten a las aseguradoras recopilar datos en tiempo real sobre activos asegurados, como vehículos y propiedades. Esto les brinda una visión más precisa del riesgo y permite la creación de pólizas basadas en el comportamiento del cliente.</p>
                    
                        <img style="width: 100%;" src="./../../../../assets/img/blog/blog1-detail.png" alt="">
                        <p class="subtitle-img">Fuente: Shutterstock</p>
    
                        <h3 class="subtitle-parrafo-blog"><strong>3. </strong> Blockchain para la Gestión de Políticas y Reclamaciones</h3>
                        <p class="parrafo-normal">Blockchain se ha convertido en una herramienta esencial para garantizar la seguridad y la transparencia en la industria de seguros. Permite una gestión eficiente de pólizas y reclamaciones, reduciendo la posibilidad de fraude y acelerando los procesos.</p>
                        <h3 class="subtitle-parrafo-blog"><strong>4. </strong>Chatbots y Atención al Cliente Digital</h3>
                        <p class="parrafo-normal">Los chatbots y las soluciones de atención al cliente digital están transformando la forma en que las aseguradoras interactúan con sus clientes. Estos sistemas pueden brindar respuestas rápidas y precisas a consultas, mejorar la experiencia del cliente y liberar recursos humanos para tareas más estratégicas.</p>
                        <h3 class="subtitle-parrafo-blog"><strong>5. </strong> Big Data y Analítica Avanzada</h3>
                        <p class="parrafo-normal">La capacidad de analizar grandes conjuntos de datos está impulsando la toma de decisiones informadas en el sector de seguros. Esto no solo beneficia a las empresas al prevenir riesgos y optimizar operaciones, sino que también permite la personalización de productos y servicios para satisfacer las necesidades individuales de los clientes.</p>
    
                        <p class="parrafo-with-border-left">
                            En conclusión, las aseguradoras y brokers que abracen estas tendencias estarán en la mejor posición para prosperar en la industria de seguros de Iberoamérica en los próximos años. ¿Está su empresa lista para el desafío tecnológico?
                        </p>
                        <div class="redes-container" style="">
                            <span style="margin-right: 10px;">
                                Compartir:
                            </span>
                            <a href="https://www.facebook.com/Teggium/" target="_blank"><img  src="./../../../../assets/img/facebook.png" alt=""></a>
                            <a href="https://twitter.com/teggium" target="_blank"><img src="./../../../../assets/img/x.png" alt="" style="margin: 0 10px;"> </a>
                            <a href="https://www.linkedin.com/company/teggium/" target="_blank"><img src="./../../../../assets/img/linkd.png" alt=""></a>
                        </div>
                        
                    </div>
                </div>
    
    
            </div>
    
        </div>
    </some-element>
    <some-element *ngSwitchCase="2"><div class="detalle-blog-container">
        <div class="detalle-blog-content">
            <div class="detalle-blog-return" (click)="returnBlogs()">
                <img src="./../../../../assets/img/arow-return.png" alt="">
                <span>Volver al blog</span>
            </div>

            <p class="small_team_card_label">SEGUROS DE HOGAR</p>
            <p class="detail-title title-bold">La importancia de los seguros en caso de sismo</p>
            <div class="small_team_card_detail">
                <img src="../../../assets/img/iso_bg.png" alt="">
                <p>EQUIPO TEGGIUM - 15 SEPT, 2023</p>
            </div>
            <img class="detail-blog-img" src="./../../../../assets/img/blog/blog2-big.png" alt="">

            <div class="detalle-blog-info-container">
                <div class="detalle-blog-info-content">
                    <p class="parrafo-normal">Los sismos, fenómenos telúricos que sacuden la tierra, son una realidad en muchas partes del mundo y pueden tener consecuencias devastadoras para personas y propiedades. Uno de los aspectos más importantes de la preparación en caso de temblor es contar con un seguro adecuado. 
                        </p>
                    <p class="parrafo-normal">
                        Los seguros en caso de sismo, también conocidos como seguros contra terremotos, están diseñados específicamente para proteger a las personas y sus propiedades. Estos seguros pueden cubrir una variedad de daños, incluyendo:
                    </p>

                    <h3 class="subtitle-parrafo-blog"><strong>1. </strong>Daños a la vivienda:</h3>

                    <p class="parrafo-normal">Los sismos pueden causar daños estructurales significativos en las casas, lo que puede resultar en costosas reparaciones. Un seguro contra sismos puede ayudar a cubrir estos gastos, lo que permite a los propietarios reconstruir o reparar sus hogares.</p>

                    <h3 class="subtitle-parrafo-blog"><strong>2. </strong> Pérdida de pertenencias:</h3>

                    <p class="parrafo-normal">Además de daños a la vivienda, los sismos pueden causar daños a los bienes personales. Un seguro adecuado puede ayudar a reemplazar o reparar los objetos dañados o perdidos, como muebles, electrodomésticos y objetos de valor.</p>
                
                    <h3 class="subtitle-parrafo-blog"><strong>3. </strong> Gastos de alojamiento temporal:</h3>
                    <p class="parrafo-normal">Si una vivienda se vuelve inhabitable debido a un sismo, un seguro contra terremotos puede cubrir los gastos de alojamiento temporal, como el alquiler de una vivienda alternativa u hospedaje en un hotel.</p>
                    <h3 class="subtitle-parrafo-blog"><strong>4. </strong>Responsabilidad civil:</h3>
                    <p class="parrafo-normal">Si el sismo causa daños a la propiedad de terceros o lesiones a otras personas, un seguro contra terremotos puede ayudar a cubrir los costos legales y las indemnizaciones.</p>
        
                    <h3 class="subtitle-question subtitle-parrafo-blog">¿Cómo obtener un seguro contra sismos?</h3>
                    <p class="parrafo-normal">Para obtener un seguro contra sismos, es importante contactar a una compañía de seguros de confianza. Estas compañías evaluarán la ubicación de tu vivienda, su estructura y otros factores para determinar el nivel de riesgo sísmico y, en consecuencia, el costo de la póliza. Aunque estos seguros pueden aumentar el costo de la prima de seguro de vivienda regular, la inversión puede resultar esencial en caso de un sismo. </p>
                    <img style="width: 100%;" src="./../../../../assets/img/blog/blog2-detail.png" alt="">
                    <p class="subtitle-img">Fuente: Shutterstock</p>

                    <p class="parrafo-normal">Además de contar con un seguro, es importante estar preparados de otras maneras. Esto incluye tener un kit de emergencia con suministros esenciales, un plan de evacuación familiar y la formación sobre cómo reaccionar durante el temblor. La preparación y la prevención son herramientas clave para proteger a tu familia y bienes. 
                       </p>
                    <p class="parrafo-normal"> En Cibergestión Insurance entendemos los desafíos operativos que enfrenta el sector asegurador y nos enfocamos en ofrecer soluciones innovadoras respaldadas por herramientas tecnológicas avanzadas. </p>
                    <p class="parrafo-with-border-left">
                        Gracias a nuestra rigurosa metodología y a un equipo altamente capacitado, analizamos minuciosamente todos los procesos y subprocesos de la cadena de valor para brindar soluciones óptimas en las áreas más desafiantes.
                    </p>
                    <div class="redes-container" style="">
                        <span style="margin-right: 10px;">
                            Compartir:
                        </span>
                        <a href="https://www.facebook.com/Teggium/" target="_blank"><img  src="./../../../../assets/img/facebook.png" alt=""></a>
                        <a href="https://twitter.com/teggium" target="_blank"><img src="./../../../../assets/img/x.png" alt="" style="margin: 0 10px;"> </a>
                        <a href="https://www.linkedin.com/company/teggium/" target="_blank"><img src="./../../../../assets/img/linkd.png" alt=""></a>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </some-element>
    <some-element *ngSwitchCase="3"><div class="detalle-blog-container">
        <div class="detalle-blog-content">
            <div class="detalle-blog-return" (click)="returnBlogs()">
                <img src="./../../../../assets/img/arow-return.png" alt="">
                <span>Volver al blog</span>
            </div>

            <p class="small_team_card_label">TECNOLOGÍA</p>
            <p class="detail-title title-bold">Teggium: Nuestra tecnología como aliada de la eficiencia operativa</p>
            <div class="small_team_card_detail">
                <img src="../../../assets/img/iso_bg.png" alt="">
                <p>Andrea Plaza - Directora de Estrategia Comercial - 21 DIC, 2023</p>
            </div>
            <img class="detail-blog-img" src="./../../../../assets/img/blog3-large1.png" alt="">

            <div class="detalle-blog-info-container">
                <div class="detalle-blog-info-content">
                    <p class="parrafo-normal">
                        En el dinámico entorno del sector asegurador, Teggium se destaca como pionero al ofrecer soluciones tecnológicas avanzadas para optimizar procesos clave en emisión, siniestros y KYC. Nuestra plataforma PRESTO ha sido diseñada para responder de manera ágil y efectiva a las demandas dinámicas de esta industria en constante evolución. Al integrar innovación y eficiencia, Teggium proporciona beneficios que impulsan la excelencia operativa en el sector y elevan los estándares en la entrega de servicios.
                        </p>

                    <h3 class="subtitle-parrafo-blog"><strong>1. </strong>Optimización en la Emisión de Pólizas: </h3>
                    <ul style="margin-left: 60px;">
                        <li class="parrafo-normal">
                            <strong style="color: black;">Organización, depuración y estructuración de información:</strong> Nuestra tecnología y equipo capacitado se encargan de organizar, depurar y estructurar la información para garantizar emisiones de pólizas precisas y sin errores.
                        </li>
                        <li class="parrafo-normal">
                            <strong style="color: black;">Fortalecimiento de procesos operativos y transformación digital: </strong> Brindamos mayor agilidad y precisión en la emisión de pólizas que permite una rápida adaptación a las demandas cambiantes del mercado y mejor experiencia del usuario.
                        </li>
                    </ul>

                    <h3 class="subtitle-parrafo-blog"><strong>2. </strong>Automatización eficiente en Siniestros: </h3>
                    <ul style="margin-left: 60px;">
                        <li class="parrafo-normal">
                            <strong style="color: black;">Respuesta Rápida: </strong> Agilizamos los procesos de gestión de siniestros, permitiendo respuestas rápidas y eficientes que se trasladan en una mayor satisfacción del cliente y en una reducción de costos.
                        </li>
                        <li class="parrafo-normal">
                            <strong style="color: black;">Trazabilidad y transparencia:  </strong> el control del trámite del siniestro en la plataforma PRESTO permite brindar información de forma automática en línea y mediante notificaciones a los involucrados, reduciendo el estrés que el desconocimiento provoca.
                        </li>
                    </ul>
                    <img style="width: 100%;" src="./../../../../assets/img/blog3-large2.png" alt="">

                    <h3 class="subtitle-parrafo-blog"><strong>3. </strong>Gestión de Compliance: </h3>
                    <ul style="margin-left: 60px;">
                        <li class="parrafo-normal">
                            <strong style="color: black;">Conoce a tus clientes con confianza: </strong> La plataforma facilita la recopilación, verificación y validación de la información esencial sobre los clientes, asegurando que cumplan los requisitos legales y normativos establecidos.
                        </li>
                        <li class="parrafo-normal">
                            <strong style="color: black;">Adaptabilidad a la normativa vigente:  </strong>Las regulaciones y leyes cambian constantemente, por ello, PRESTO es altamente adaptable y se actualiza de manera regular para cumplir con las últimas normativas y requisitos legales.
                        </li>
                    </ul>

                    <h3 class="subtitle-parrafo-blog"><strong>4. </strong>Enfoque Innovador en Ciberseguridad para el Sector Asegurador:</h3>
                    <ul style="margin-left: 60px;">
                        <li class="parrafo-normal">
                            <strong style="color: black;">Protección de Datos Sensibles: </strong> Integramos soluciones de ciberseguridad de vanguardia a través del uso de los ISO 9001 e ISO 6001 con el objetivo de salvaguardar el flujo de la información sensible de los asegurados.
                        </li>
                        <li class="parrafo-normal">
                            <strong style="color: black;">Respuesta Rápida a Amenazas Cibernéticas:  </strong>establecemos un estándar elevado en la protección contra riesgos cibernéticos, con detección temprana y respuestas rápidas ante posibles amenazas.
                        </li>
                    </ul>

                    <p class="parrafo-with-border-left">
                        En conclusión, Teggium no solo aborda la gestión desde una perspectiva financiera, sino que también lidera la incorporación de tecnología avanzada en los procesos operativos clave del sector asegurador. Al anticiparse a los desafíos y utilizar datos de manera inteligente, Teggium establece un estándar elevado en la evolución del sector asegurador hacia un futuro más eficiente y tecnológicamente avanzado. Estamos a la vanguardia, impulsando la transformación digital en el corazón del seguro. Únete a nosotros en este viaje hacia un mañana más eficiente y tecnológicamente innovador.
                    </p> 

                    <p class="parrafo-normal">¡Gracias por formar parte de esta revolución con Teggium!</p>
                    <div class="redes-container" style="">
                        <span style="margin-right: 10px;">
                            Compartir:
                        </span>
                        <a href="https://www.facebook.com/Teggium/" target="_blank"><img  src="./../../../../assets/img/facebook.png" alt=""></a>
                        <a href="https://twitter.com/teggium" target="_blank"><img src="./../../../../assets/img/x.png" alt="" style="margin: 0 10px;"> </a>
                        <a href="https://www.linkedin.com/company/teggium/" target="_blank"><img src="./../../../../assets/img/linkd.png" alt=""></a>
                    </div> 
                </div>
            </div>
        </div>

    </div>
    </some-element>
    <some-element *ngSwitchCase="4"><div class="detalle-blog-container">
        <div class="detalle-blog-content">
            <div class="detalle-blog-return" (click)="returnBlogs()">
                <img src="./../../../../assets/img/arow-return.png" alt="">
                <span>Volver al blog</span>
            </div>

            <p class="small_team_card_label">TECNOLOGÍA</p>
            <p class="detail-title title-bold">Optimizamos la eficiencia en el sector asegurador: el poder de las reglas de negocio</p>
            <div class="small_team_card_detail">
                <img src="../../../assets/img/iso_bg.png" alt="">
                <p>Beatriz Corona - Directora de Operaciones - 12 ENE, 2024</p>
            </div>
            <img class="detail-blog-img" src="./../../../../assets/img/blog/blog4.1.png" alt="">

            <div class="detalle-blog-info-container">
                <div class="detalle-blog-info-content">
                    <p class="parrafo-normal">
                        En el mundo del sector asegurador,  la información y rapidez es clave, la automatización de procesos se ha convertido en un aliado fundamental. Una herramienta valiosa para alcanzar este objetivo es el uso de reglas de negocio. Estas reglas no solo agilizan las operaciones, sino que también actúan como guardianes eficientes contra la duplicidad de datos, un desafío que puede afectar la integridad de la información y la toma de decisiones.
                    </p>
                    <h3 class="subtitle-question subtitle-parrafo-blog">¿Cuáles son las reglas de negocio y cómo funcionan en el sector asegurador?</h3>

                    <p class="parrafo-normal">
                        Las reglas de negocio son conjuntos de directrices que definen el comportamiento de un sistema o aplicación. En el contexto del sector asegurador, estas reglas se implementan para automatizar procesos y asegurar la coherencia de los datos. Su función principal es establecer patrones que el sistema seguirá de manera autónoma, reduciendo así la intervención manual y la posibilidad de errores humanos.
                    </p>
                    <h3 class="subtitle-question subtitle-parrafo-blog">
                        Automatización para reducir la duplicidad de datos
                    </h3>
                    <p class="parrafo-normal">
                        La duplicidad de datos es un problema común en el sector asegurador que puede surgir debido a la entrada manual de información o a la falta de un sistema centralizado. Las reglas de negocio son la respuesta a este desafío, ya que permiten establecer criterios para identificar y gestionar datos duplicados de manera eficiente.
                    </p>
                    <p class="parrafo-normal">
                        Por ejemplo, un asegurado realiza cambios en los datos de contacto de su póliza como la dirección. Con reglas de configuración adecuadas, el sistema Presto de Teggium puede automáticamente identificar si existe otra póliza asociada a la misma dirección, evitando así la creación de duplicados innecesarios.
                    </p>
                    <img style="width: 100%;" src="./../../../../assets/img/blog/blog4.2.png" alt="">

                    <h3 class="subtitle-question subtitle-parrafo-blog">
                        Beneficios de implementar reglas de configuración en el Sector Asegurador
                    </h3>
                    <p class="parrafo-normal"style="margin: 5px 0px;">
                        En cuanto a la eficiencia operativa, la automatización de procesos:
                    </p>

                    <ul style="margin-left: 60px;">
                        <li class="parrafo-normal" style="margin: 5px 0px;">
                            Reduce la carga de trabajo manual, permitiendo que los profesionales del sector se centren en tareas más estratégicas.
                        </li>
                        <li class="parrafo-normal" style="margin: 5px 0px;">
                            Se garantiza la consistencia de los datos, mejorando la calidad de la información almacenada.
                        </li>
                        <li class="parrafo-normal" style="margin: 5px 0px;">
                            Mejora la experiencia del cliente al evitar la duplicidad de datos, generando confianza, comunicación clara y precisa.
                        </li>
                        
                    </ul>

                    <p class="parrafo-normal" >
                        La intervención humana suele ser propensa a errores. Las reglas de negocio minimizan esta posibilidad al seguir patrones predefinidos de manera precisa aumentando la productividad.
                    </p>

                    <p class="parrafo-normal" >
                        <strong style="color: black;">Desafíos y consideraciones en la implementación:</strong> A pesar de sus beneficios, la implementación de reglas de configuración requiere un análisis cuidadoso de los procesos existentes y una adaptación adecuada a las necesidades específicas de cada entidad aseguradora. La colaboración entre equipos de TI, analistas de datos y profesionales del sector es esencial para garantizar una implementación exitosa.
                    </p>

                    <p class="parrafo-with-border-left">
                        En conclusión, las reglas de configuración son una herramienta poderosa que Teggium emplea para mejorar la eficiencia, garantizar la integridad de los datos y mitigar el riesgo de duplicidad así continuamos avanzando hacia un futuro más ágil y tecnológico para beneficio de nuestros clientes.
                    </p> 
                    <div class="redes-container" style="">
                        <span style="margin-right: 10px;">
                            Compartir:
                        </span>
                        <a href="https://www.facebook.com/Teggium/" target="_blank"><img  src="./../../../../assets/img/facebook.png" alt=""></a>
                        <a href="https://twitter.com/teggium" target="_blank"><img src="./../../../../assets/img/x.png" alt="" style="margin: 0 10px;"> </a>
                        <a href="https://www.linkedin.com/company/teggium/" target="_blank"><img src="./../../../../assets/img/linkd.png" alt=""></a>
                    </div> 
                </div>
            </div>
        </div>

    </div>
    </some-element>        
  </container-element>
<div class="detalle-blog-relacionados-container">
    <div class="detalle-blog-relacionados-content">
        <h3 class="detalle-blog-relacionados-title title-bold">Artículos relacionados</h3>
        <div class="card-container">
            <ng-container *ngFor="let card of array1">
                <div class="small_team_card" (click)="verBlog(card.number)">
                    <img class="small_team_card_img" [src]="card.img" alt="">
                    <p class="small_team_card_label">{{card.label}}</p>
                    <p class="small_team_card_text">{{card.text}}</p>
                    <div class="small_team_card_detail">
                        <img src="../../../assets/img/iso_bg.png" alt="">
                        <p>{{card.detail}}</p>
                    </div>
                    </div>
            </ng-container>
        </div>
        <div class="banner-dark-container">
            <div class="banner-dark-content">
                <img class="banner-dark-img-left" src="./../../../assets/img/banner-dark/lines-banner-left.png" alt="">
                <div class="banner-dark-center-content">
                    <p class="banner-dark-title title-bold">Inicia ahora con nosotros como
                        <mark class="white"> tus socios estratégicos</mark>
                    </p>
                    <button (click)="goContactPage()" class="banner-dark-button button-size">
                        Contáctanos
                    </button>
                </div>
                <img class="banner-dark-img-right" src="./../../../assets/img/banner-dark/lines-banner-right.png"
                    alt="">
            </div>
        </div>
    </div>
</div>
