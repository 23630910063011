<div class="tecnologia-banner-container">
    <div class="tecnologia-banner-img-container ">
        <!-- d-none d-sm-block -->
        <img class="tecnologia-banner-img" src="./../../../assets/img/tecnologia-banner/tecnologia-banner.png" />
    </div>
    
    <div class="tecnologia-banner-content">
        <div class="tecnologia-content-info">
            <div class="tecnologia-content-info-border">
                <p class="tecnologia-banner-title title-bold">Te presentamos a Presto</p>
                <div class="footer-borders-container">
                    <div class="container-left">
                        <div class="rombo-blue-left"></div>
                    </div>
                    <div  class="container-right">
                        <div class="rombo-blue-rigth"></div>
                    </div>
                </div>
            </div>
            <div class="tecnologia-banner-img-container-mobile">
                <img class="tecnologia-banner-img-mobile" src="./../../../assets/img/tecnologia-banner/tecnologia-banner.png" />
            </div>
            <p class="tecnologia-banner-subtitle subtitle-normal">La solución integral para los procesos más desafiantes del sector asegurador, con la cuál se simplifican y optimizan los procesos operativos utilizando reglas de negocio y automatización de tareas con la última tecnología.</p>
            <button (click)="goContactPage()" class="tecnologia-banner-button button-size-second">
                Contáctanos
            </button> 
        </div>
    </div>
</div>
