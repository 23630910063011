import { Component,ViewChild,inject } from '@angular/core';
import { Router } from '@angular/router';
import Swiper from 'swiper';
import { register,SwiperContainer } from 'swiper/element/bundle';

register()
register()


@Component({
  selector: 'app-inicio-carousel',
  templateUrl: './inicio-carousel.component.html',
  styleUrls: ['./inicio-carousel.component.scss']
})
export class InicioCarouselComponent {

  public router = inject(Router)

  @ViewChild('elemento') elemento!: HTMLElement;

  public swiper!:SwiperContainer

  ngOnInit(){
    this.swiper = document.querySelector('swiper-container') as SwiperContainer;
    Object.assign(this.swiper, {
      speed: 4000,
      autoplay: {
        disableOnInteraction: false,
        delay: 4000
      },
      effect: "fade",
      fadeEffect: {
        // crossFade: true
    },
      loop:true,
      grabCursor: true,
      pagination: true,
    });
    this.swiper.initialize()


  
      
    }

    goToSoluciones(){
      document.querySelector("#soluciones")!.scrollIntoView();
    }

    goToContactanos(){
      this.router.navigateByUrl('/contacto')
    }


    slides = [
      {
        img: "./../../../assets/img/inicio-carousel/axa-cliente.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/sura-cliente.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/gnp-cliente.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/metlife-cliente.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/afirme-cliente.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/gnp-cliente.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/metlife-cliente.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/HDI-Logo-blanco.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/logo-general-de-seguros-blanco.png"
      },
      {
        img: "./../../../assets/img/inicio-carousel/marsh-blanco.png"
      },
    ];
    slideConfig = {
      "slidesToShow": 5,
      "slidesToScroll": 1,
      "autoplay": true,
      'infinite': true,
      "arrows": false,
      "mobileFirst": true,

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 766,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
  
          breakpoint: 220,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
    
        }
      ]
    };
}
