import { Component,inject } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-inicio-blog',
  templateUrl: './inicio-blog.component.html',
  styleUrls: ['./inicio-blog.component.scss']
})
export class InicioBlogComponent {

  public router = inject(Router)
  
  customOptions: OwlOptions = {
    // center:true,
    autoWidth: true,
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoHeight:true,
    responsive: {
      0: {
        items: 1.25,
        margin:20,
        autoHeight: false
      },
      576: {
        items: 2.25,
        margin:20,
      },
      740: {
        items: 2.25,
        margin: 20
      },
      940: {
        margin:40,
        items: 2.25
        
      }
    },
    nav: false
  }

  goToActualidad(){
    this.router.navigateByUrl('/actualidad')
  }

  goToActualidadFromBlog(idBlog:number){
    this.router.navigateByUrl(`/blog/${idBlog}`)
  }

  

}
