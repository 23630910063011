import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EmailService } from './services/email/email.service';
import { RoutesService } from './services/routes/routes.service';
// import { filter } from 'rxjs/operators';

// declare var gtag:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public urlComponent = inject(RoutesService);
  public emailService = inject(EmailService)
  public loading = false

  title = 'teggium';
  isClose: boolean = false;

  constructor(private router: Router){

    // FIRST WAY TO MAKE SEND ROUTE GOOGLE ANALITYCS
    // const navEndEvents$ = this.router.events
    //   .pipe(
    //     filter(event => event instanceof NavigationEnd)
    //   );
    //   console.log(window.location.pathname)
    // navEndEvents$.subscribe((event:any)=>{
    //   console.log('entro',event.urlAfterRedirects)
    //   gtag('config','G-LGPXZQC72Y',{
    //         'page_path': window.location.pathname
    //       })
    // })

  // SECOND WAY TO MAKE SEND ROUTE GOOGLE ANALITYCS
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     gtag('config', 'G-LGPXZQC72Y', {
    //       page_path: `${event.urlAfterRedirects}`
    //     });
    //   }
    // });
  }

  ngOnInit() {
    this.emailService.status.subscribe((val: boolean) => {
        this.loading = val;
    });
  }

  goContactPage() {
    this.router.navigateByUrl('/contacto');
  }

  ScrollIntoView(elem: string){
    if(this.router.url === '/inicio'){
      document.getElementById("socios")!.scrollIntoView();
    }
    this.urlComponent.setComponentName('socios');
    this.router.navigate(['/inicio']);
  }

  closeSideBar() {
    this.isClose = !this.isClose;
    this.hideScroll(this.isClose)
  }

  fnOpen(e: boolean) {
    this.isClose = e;
    this.hideScroll(this.isClose)
  }

  hideScroll(isClose:boolean){
    if(isClose) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  closeMenuMob(closeMob: boolean) {
    this.isClose = closeMob;
    this.hideScroll(this.isClose);
  }

  inicioTop() {
    if(this.router.url === '/inicio'){
      document.getElementById("inicio")!.scrollIntoView();
    }
  }
}
