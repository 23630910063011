<div class="error-container">
    <img class="error-lines d-none d-sm-block" src="./../../../../assets/img/talento/talento-lines.png" alt="">
    <div class="error-content">
        <div class="error-title-container">
            <div class="error-content-info-border">
                <p class="error-banner-title title-bold">Lo sentimos, la página que buscas no se encuentra disponible
                </p>
                <div class="footer-borders-container">
                    <div class="container-left">
                        <div class="rombo-blue-left"></div>
                    </div>
                    <div class="container-right">
                        <div class="rombo-blue-rigth"></div>
                    </div>
                </div>
            </div>
            <p class="error-subtitle subtitle-normal">Te invitamos a continuar revisando el contenido que tenemos para ti.</p>
            <button class="error-button button-size-second" (click)="goToInicio()">
                Volver al Inicio
            </button>
        </div>

    </div>
</div>