<div class="taps-main-container">
    <div class="taps-container">
        <div class="taps-header">
            <div class="titles-container">
                <img class="arrow-skyblue-left" src="./../../../assets/img/arrow-blue.png" alt="">
                <h2 class="taps-title-header title-bold">
                    Transformamos la eficiencia operativa: <br>
                    tu socio estratégico para el sector asegurador.
                </h2>
                <h3 class="taps-subtitle-header subtitle-normal">Con nuestros 3 pilares, se capaz de poder hacer más.</h3>
            </div>
        </div>
        <div class="taps-body">
            <img class="arrow-skyblue-left-mobile" src="./../../../assets/img/arrow-blue.png" alt="">
            <app-taps> 
                <app-tap-item [tabTitle]="'Metodología'">
                    <div class="metodologia-container">
                        <div class="metodologia-img">
                            <img src="./../../../../assets/img/inicio-taps/tap-metodologia-img.png" alt="">
                        </div>
                        <div class="metodologia-content">
                            <h2 class="metodologia-title title-bold">Metodología</h2>
                            <p class="metodologia-subtitle subtitle-normal">Contamos con IEO (Índice de Excelencia Operativa), es nuestra metodología propia que recoge el nivel de acercamiento de la operación de una empresa a la excelencia operacional.</p>
                            <ul class="allowed-documents-list-container">
                                    <li class="allowed-documents-list-item subtitle-normal">Visibilidad y Trazabilidad</li>
                                    <li class="allowed-documents-list-item subtitle-normal">Mejora de procesos y Automatización</li>
                                    <li class="allowed-documents-list-item subtitle-normal">Transformación digital</li>
                            </ul>
                            <button (click)="goToContactanos()" class="metodologia-button button-size">
                                Contáctanos
                            </button> 
                        </div>
                    </div>
                </app-tap-item>
                <app-tap-item tabTitle="Hub de Operaciones especializado">
                    <div class="metodologia-container">
                        <div class="metodologia-img">
                            <img src="./../../../../assets/img/inicio-taps/tap-hub-img.png" alt="">
                        </div>
                        <div class="metodologia-content">
                            <h2 class="metodologia-title title-bold">Hub de Operaciones especializado</h2>
                            <ul class="allowed-documents-list-container">
                                    <li class="allowed-documents-list-item subtitle-normal">Enfoque en eficiencia y resultados</li>
                                    <li class="allowed-documents-list-item subtitle-normal">Innovación y gestión tecnológica</li>
                                    <li class="allowed-documents-list-item subtitle-normal">Agilidad en las operaciones y procesos</li>
                                    <li class="allowed-documents-list-item subtitle-normal">Modelo escalable</li>
                            </ul>
                            <button (click)="goToContactanos()" class="metodologia-button button-size">
                                Contáctanos
                            </button> 
                        </div>
                    </div>
                </app-tap-item>
                <app-tap-item tabTitle="Tecnología">
                    <div class="metodologia-container">
                        <div class="metodologia-img">
                            <img src="./../../../../assets/img/inicio-taps/tap-tecnologia-img.png" alt="tap-tecnologia-img">
                        </div>
                        <div class="metodologia-content">
                            <h2 class="metodologia-title title-bold">Tecnología</h2>
                            <p class="metodologia-subtitle-diferent subtitle-normal">Conoce más sobre nuestra línea tecnológica especializada en Seguros: Presto.</p>
                            <button (click)="goToTech()" class="metodologia-button button-size mt-3">
                                Conoce más
                            </button> 
                        </div>
                    </div>
                </app-tap-item>
            </app-taps>
            <!-- <img class="arrow-skyblue-right-mobile" src="./../../../assets/img/arrow-blue.png" alt=""> -->
        </div>
    </div>
    <!-- <img class="arrow-skyblue-right" src="./../../../assets/img/arrow-blue.png" alt=""> -->
</div>