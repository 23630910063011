<div class="descubre-container">
    <div class="descubre-row">
        <div class="col-12 col-lg-5">
            <img src="../../../../assets/img/lineas-descubre.png" draggable="false" alt="lineas">
            <div class="descubre-texto">
                <p>
                    El sector asegurador está en continua evolución tecnológica y operativa.
                </p>
                <strong>
                    ¡Descubre cómo trabajamos juntos para lograr esta transformación!
                </strong>
                <div>
                    <button class="descubre-button button-size-second" (click)="goToContactanos()">Conoce más</button>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-7 d-flex" style="position: relative;">
            <!-- <div style=" width: 70%; position: absolute;top: 30px;left: 120px; border-radius: 20px;"> -->
            <!-- </div> -->
            <!-- <div> -->
                <!-- https://drive.google.com/file/d/1v38LpfqtjJZWqYefP40dAByI_Ess0BeY/view -->
                <!-- ./../../../../assets/img/video/video-teggium.mp4 -->
                <!-- https://drive.google.com/file/d/1XEM5un_69PSm5v06fxGl7sjz3PMcR8E6/view -->
            <!-- <video class="video" controls (canplay)="pauseVideo(videoplayer)" #videoPlayer>
                <source src="https://drive.google.com/file/d/1XEM5un_69PSm5v06fxGl7sjz3PMcR8E6/view" type="video/mp4"  >
         
                Your browser does not support the video tag.
            </video> -->
            <!-- <iframe class="video" src='https://drive.google.com/file/d/1XEM5un_69PSm5v06fxGl7sjz3PMcR8E6/preview' >
            </iframe> -->
            <!-- <iframe class="video" src='https://www.youtube.com/watch?v=XooveMhcZWw'  width="100%" height="518vh" frameborder="0" allowfullscreen
                webkitallowfullscreen mozallowfullscreen>
            </iframe> -->
            <!-- ?rel=0 -->
            <iframe  class="video" width="100%" height="518vh" src="https://www.youtube.com/embed/XooveMhcZWw?rel=0" title="En Teggium, te ofrecemos soluciones vanguardistas y tecnológicas 🚀" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <!-- <iframe src="https://drive.google.com/file/d/1v38LpfqtjJZWqYefP40dAByI_Ess0BeY/preview"  height="518vh" allow="autoplay"></iframe> -->
            <img src="../../../../assets/img/laptop3.png" width="100%" draggable="false" alt="img">
            <!-- </div> -->
        </div>
    </div>
</div>