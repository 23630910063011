import { Component } from '@angular/core';

@Component({
  selector: 'app-inicio-cobertura',
  templateUrl: './inicio-cobertura.component.html',
  styleUrls: ['./inicio-cobertura.component.scss']
})
export class InicioCoberturaComponent {
  showPeru = false;
  showChile = false;
  showMexico = false;
  showColombia = false;
  showPanama = false;
  showBrasil = false;
  showEspana = false;
  countrySelected:any= {
    nombrePais: ''
  }

  listPaises = [
    {
      nombrePais : 'peru',
      persona: 'Francisco Castillo Stack',
      pais: 'Chile y Perú',
      correo: 'fcastillo@teggium.com',
      telefono: '(+56) 9 95798200',
      direcciones: [
        'Miraflores 383 Piso 13, Santiago de Chile, Chile.'
      ]
    },
    {
      nombrePais : 'chile',
      persona: 'Francisco Castillo Stack',
      pais: 'Chile y Perú',
      correo: 'fcastillo@teggium.com',
      telefono: '(+56) 9 95798200',
      direcciones: [
        'Miraflores 383 Piso 13, Santiago de Chile, Chile.'
      ]
    },
    {
      nombrePais : 'mexico',
      persona: 'Daniela Lagunas Alvarez',
      pais: 'México',
      correo: 'clagunas@teggium.com',
      telefono: '(+52) 551116 2771',
      direcciones: [
        'Periférico Sur 3325, CDMX, México.',
        'Av. Peñuelas 18, Santiago de Querétaro, Queretaro, México.'
      ]
    },
    {
      nombrePais : 'colombia',
      persona: 'Leonardo Ramirez Niño',
      pais: 'Colombia y Panamá',
      correo: 'leonardo.ramirez@teggium.com',
      telefono: '(+57) 316 624 4816',
      direcciones: [
        'Cra. 50 #93A-29, Rionegro, Bogotá, Colombia',
      ]
    },
    {
      nombrePais : 'panama',
      persona: 'Leonardo Ramirez Niño',
      pais: 'Colombia y Panamá',
      correo: 'leonardo.ramirez@teggium.com',
      telefono: '(+57) 316 624 4816',
      direcciones: [
        'Cra. 50 #93A-29, Rionegro, Bogotá, Colombia',
      ]
    },
    {
      nombrePais : 'brasil',
      persona: 'Paulo Roberto Carvalho',
      pais: 'Brasil',
      correo: 'pcarvalho@teggium.com',
      telefono: '(+55) 19 99837-2856',
      direcciones: [
        'Rua Gomes de Carvalho, 1507, Bloco A/B – Vila Olimpia –São Paulo-SP.',
      ]
    },
    {
      nombrePais : 'espana',
      persona: 'Abel Martínez Murillo',
      pais: 'España',
      correo: 'amartinezm@teggium.com',
      telefono: '(+34) 646 008 379',
      direcciones: [
        'C/ Miguel Yuste, 26. Madrid, España.',
      ]
    }
  ]


  setCountrySelected(pais:string){
    this.listPaises.forEach(country=>{
      if(country.nombrePais === pais){
        this.countrySelected= country     
      }
    })
  }

enterCountry(event:any, pais:string){
  this.setCountrySelected(pais)
}

}
