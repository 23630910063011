import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-inicio-solution',
  templateUrl: './inicio-solution.component.html',
  styleUrls: ['./inicio-solution.component.scss']
})
export class InicioSolutionComponent {

  showEmisionInformation: boolean = false
  showSiniestroInformation: boolean = false
  showCumplimientoInformation: boolean = false

  public router = inject(Router)

  customOptions: OwlOptions = {
    autoWidth: true,
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoHeight:true,
    responsive: {
      0: {
        items: 1.25,
        margin:0,
        autoHeight: false
      },
      576: {
        items: 2.25,
        margin:0,
      },
      740: {
        items: 2.25,
        margin: 0
      },
      940: {
        margin:0,
        items: 2.25
        
      }
    },
    nav: false
  }

  goToContactanos(){
    this.router.navigateByUrl('/contacto')
  }

  enterEmision($event:any){
    this.showEmisionInformation = true
  }

  outEmision($event:any){
    this.showEmisionInformation = false
  }

  enterSiniestro($event:any){
    this.showSiniestroInformation = true
  }

  outSiniestro($event:any){
    this.showSiniestroInformation = false
  }
  enterCumplimiento($event:any){
    this.showCumplimientoInformation = true
  }

  outCumplimiento($event:any){
    this.showCumplimientoInformation = false
  }
  
  

}