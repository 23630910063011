<div class="solution-container">
    <img class="arrow-skyblue-left" src="./../../../assets/img/arrow-blue.png" alt="">
    <div class="solution-content">
        <div class="solution-info">
            <p class="solution-title title-bold">Las soluciones que tenemos a tu alcance</p>
            <p class="subtitle-normal">
                Teggium ofrece soluciones para optimizar los lentos y tediosos procesos en los principales ramos de seguros con soluciones digitales.
            </p>
            <button class="solution-button button-size-second" (click)="goToContactanos()">Contáctanos</button>
        </div>
        <div class="solution-carousel">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div (mouseover)="enterEmision($event)" (mouseout)="outEmision($event)" >
                        <div *ngIf="!showEmisionInformation; else showEmision" class="solution_group_item">
                            <p class="solution_group_item_title">Emisión</p>
                            <img src="./../../../../assets/img/inicio-solution/emision.svg" alt="" height="250px"
                                style="margin: auto;">
                            <!-- <p class="solution_group_item_text" >Optimizamos el proceso de contratación, para satisfacción del cliente y eficiencia ampliada.</p>
                            <div class="label_item">
                                <div class="first-slide-title-container" >
                                    <p class="first-slide-title">Vehicular flotillas</p>
                                    <div class="footer-borders-container">
                                        <div class="container-left">
                                            <div class="rombo-blue-left"></div>
                                        </div>
                                        <div  class="container-right">
                                            <div class="rombo-blue-rigth"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="first-slide-title-container" >
                                    <p class="first-slide-title">Salud y Vida</p>
                                    <div class="footer-borders-container">
                                        <div class="container-left">
                                            <div class="rombo-blue-left"></div>
                                        </div>
                                        <div  class="container-right">
                                            <div class="rombo-blue-rigth"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <ng-template #showEmision>
                            <div class="solution_group_item">
                                <p class="solution_group_item_title">Emisión</p>
                                <p class="solution_group_item_content">
                                    Enfocados en la eficiencia operativa y digitalización; nuestro ecosistema PRESTO
                                    ofrece soluciones integrales para agilizar la contratación, mejorar la satisfacción
                                    del cliente en la emisión de pólizas de seguro colectivas e individuales y expandir
                                    el alcance para aseguradoras e intermediarios.
                                </p>
                            </div>

                        </ng-template>

                    </div>

                </ng-template>
                <ng-template carouselSlide>
                    <div (mouseover)="enterSiniestro($event)" (mouseout)="outSiniestro($event)" >
                        <div *ngIf="!showSiniestroInformation; else showSiniestro" class="solution_group_item">
                            <p class="solution_group_item_title">Siniestros</p>
                            <img src="./../../../../assets/img/inicio-solution/siniestros.svg" alt="" height="250px"
                                style="margin: auto;">
                            <!-- <p class="solution_group_item_text" >Enfrentamos los desafíos de los siniestros con confianza y tranquilidad.</p>
                            <div class="label_item">
                                <div class="first-slide-title-container" >
                                    <p class="first-slide-title">Perdidas totales de auto</p>
                                    <div class="footer-borders-container">
                                        <div class="container-left">
                                            <div class="rombo-blue-left"></div>
                                        </div>
                                        <div  class="container-right">
                                            <div class="rombo-blue-rigth"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="first-slide-title-container" >
                                    <p class="first-slide-title">Salud y Vida</p>
                                    <div class="footer-borders-container">
                                        <div class="container-left">
                                            <div class="rombo-blue-left"></div>
                                        </div>
                                        <div  class="container-right">
                                            <div class="rombo-blue-rigth"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <ng-template #showSiniestro>
                            <div class="solution_group_item">
                                <p class="solution_group_item_title">Siniestros</p>
                                <p class="solution_group_item_content">
                                    Con soluciones integrales para que el ecosistema asegurador enfrente los desafíos de
                                    la gestión de siniestros con confianza y eficiencia utilizando nuestra plataforma
                                    especializada. 
                                </p>
                            </div>

                        </ng-template>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div (mouseover)="enterCumplimiento($event)" (mouseout)="outCumplimiento($event)" >
                        <div *ngIf="!showCumplimientoInformation; else showCumplimiento" class="solution_group_item">
                            <p class="solution_group_item_title">Cumplimiento</p>
                            <img src="./../../../../assets/img/inicio-solution/cumpl.svg" alt="" height="250px"
                                style="margin: auto;">
                            <!-- <p class="solution_group_item_text" >En el ámbito de KYC, aplicamos una gestión integral de riesgos de lavado de activos y financiación del terrorismo.</p>
                        <div class="label_item">
                        </div> -->
                        </div>
                        <ng-template #showCumplimiento>
                            <div class="solution_group_item">
                                <p class="solution_group_item_title">Cumplimiento</p>
                                <p class="solution_group_item_content">
                                    Garantizamos el cumplimiento riguroso de las regulaciones y 
                                    leyes aplicables para las empresas y prevenimos riesgos 
                                    mediante nuestro enfoque en el proceso Know Your Customer (KYC).
                                </p>
                            </div>

                        </ng-template>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>