

<div class="estrategia-container">
    <img class="estrategia-img" src="./../../../../assets/img/three-line-left.png" />
    <img class="estrategia-img-large" src="./../../../../assets/img/three-line-largue.png" />
    <div class="estrategia-content">
        <div class="estrategia-info">
                <p class="solution-title title-bold">¿Por qué elegirnos como tus socios estratégicos?</p>
                <p class="subtitle-normal">Nuestros desarrollos Insurtech con soluciones estructuradas para el mercado asegurador.</p>
        </div>
        <div class="estrategia-carousel">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div (mouseover)="enterCostoVariable($event)" (mouseout)="outCostoVariable($event)">
                        <div *ngIf="!showCostoVariableInformation; else showCostoVariable" class="strategy_card">
                            <img class="estrategia-card-img" src="./../../../../assets/img/inicio-strategy/costo-variable.png" />
                            <p class="strategy_card_text" >Costo variable</p>
                        </div>
                        <ng-template #showCostoVariable>
                            <div  class="strategy_card_detail">
                                <p class="strategy_card_detail_text" >Costo variable</p>     
                                <p class="strategy_card_detail_subtitle">Fácil adaptación a estacionalidad y volúmenes de trabajo</p>                       
                            </div>
                        </ng-template>
                    </div>

                    
                </ng-template>  
                <ng-template carouselSlide>
                    <div (mouseover)="enterDisponibilidad($event)" (mouseout)="outDisponibilidad($event)">
                        <div *ngIf="!showDisponibilidadInformation; else showDisponibilidad" class="strategy_card">
                            <img  class="estrategia-card-img" src="./../../../../assets/img/inicio-strategy/disponibilidad.png" alt="">
                            <p class="strategy_card_text"  >Disponibilidad</p>
                        </div>
                        <ng-template #showDisponibilidad>
                            <div  class="strategy_card_detail">
                                <p class="strategy_card_detail_text" >Disponibilidad</p>     
                                <p class="strategy_card_detail_subtitle">Dedicación exclusiva al core del negocio</p>
                                <p class="strategy_card_detail_subtitle">Disponibilidad de recursos RRHH  / monetarios en las actividades del negocio</p>                        
                            </div>
                        </ng-template>
                    </div>
                    
                </ng-template>  
                <ng-template carouselSlide>
                    <div (mouseover)="enterFlexibilidad($event)" (mouseout)="outFlexibilidad($event)">
                        <div *ngIf="!showFlexibilidadInformation; else showFlexibilidad" class="strategy_card">
                            <img  class="estrategia-card-img"src="./../../../../assets/img/inicio-strategy/flexibilidad.png" alt="">
                            <p class="strategy_card_text"  >Flexibilidad</p>
                        </div>
                        <ng-template #showFlexibilidad>
                            <div  class="strategy_card_detail">
                                <p class="strategy_card_detail_text" >Flexibilidad</p>     
                                <p class="strategy_card_detail_subtitle">Fácil adaptación a estacionalidad y volúmenes de trabajo</p>
                                <p class="strategy_card_detail_subtitle">Flexibilidad para adoptar cambios en el proceso actual</p>
                                <p class="strategy_card_detail_subtitle">Rápida puesta en marcha de nuevos servicios</p>  
                                <p class="strategy_card_detail_subtitle">Servicio global.</p>                          
                            </div>
                        </ng-template>
                    </div>
                    
                </ng-template>  
                <ng-template carouselSlide>
                    <div (mouseover)="enterEspecializacion($event)" (mouseout)="outEspecializacion($event)">
                        <div *ngIf="!showEzpecializacionInformation; else showEspecializacion" class="strategy_card">
                            <img class="estrategia-card-img" src="./../../../../assets/img/inicio-strategy/especializacion.png" alt="">
                            <p class="strategy_card_text"  >Especialización</p>
                        </div>
                        <ng-template #showEspecializacion>
                            <div  class="strategy_card_detail">
                                <p class="strategy_card_detail_text" >Especialización</p>     
                                <p class="strategy_card_detail_subtitle">Enfocados en la eficiencia operativa y digitalización; nuestro ecosistema PRESTO ofrece soluciones integrales para agilizar la contratación, mejorar la satisfacción del cliente en la emisión de pólizas de seguro colectivas y expandir el alcance para aseguradoras e intermediarios.</p>                    
                            </div>
                        </ng-template>
                    </div> 
                </ng-template>  
              </owl-carousel-o>
        </div> 
    </div>
</div>
