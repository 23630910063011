import { Injectable} from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DynamicTitleStrategyService extends TitleStrategy {
    override updateTitle(routerStateSnapshot: RouterStateSnapshot): void {
        const title = this.buildTitle(routerStateSnapshot);// if you have set title in the route, you can access it like this
        if (routerStateSnapshot.root.firstChild?.url[0].path === 'blog') {
            document.title = `Blog ${routerStateSnapshot.root.firstChild?.paramMap.get('id')} - Teggium`
        } else {
            document.title = title || 'Inicio - Teggium'
        }
    }
}
