import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tap-item',
  templateUrl: './tap-item.component.html',
  styleUrls: ['./tap-item.component.scss']
})
export class TapItemComponent {
  @Input('tabTitle') title!: string;
  @Input() active = false;
}
