<div  class="actualidad-container">
    <img class="actualidad-lines d-none d-sm-block" src="./../../../../assets/img/talento/talento-lines.png" alt="">
    <div class="actualidad-content">
        <div class="talento-title-container">
            <div class="actualidad-content-info-border">
                <p class="actualidad-banner-title title-bold">Esto es lo último en el sector asegurador
                </p>
                <div class="footer-borders-container">
                    <div class="container-left">
                        <div class="rombo-blue-left"></div>
                    </div>
                    <div class="container-right">
                        <div class="rombo-blue-rigth"></div>
                    </div>
                </div>
            </div>
        </div>


        <div class="bteam_card " (click)="verBlog(2)">
            <img class="bteam_card_img" src="./../../../../assets/img/blog/blog2.png" alt="">
            <div class="bteam_card_info">
                <div class="bteam_card_detail">
                    <img src="../../../assets/img/iso_bg.png" alt="">
                    <p>EQUIPO TEGGIUM - 15 SEPT, 2023</p>
                </div>
                <p class="bteam_card_text">La importancia de los seguros en caso de sismo</p>
                <p class="bteam_card_label">SEGUROS DE HOGAR</p>
            </div>
        </div>
        <div class="card-container">
            <ng-container *ngFor="let card of array1">
                <div class="small_team_card" (click)="verBlog(card.number)">
                    <img class="small_team_card_img" [src]="card.img" alt="">
                    <p class="small_team_card_label">{{card.label}}</p>
                    <p class="small_team_card_text">{{card.text}}</p>
                    <div class="small_team_card_detail">
                        <img src="../../../assets/img/iso_bg.png" alt="">
                        <p>{{card.detail}}</p>
                    </div>
                    </div>
            </ng-container>
        </div>

        <div class="banner-dark-container">
            <div class="banner-dark-content">
                <img class="banner-dark-img-left" src="./../../../assets/img/banner-dark/lines-banner-left.png" alt="">
                <div class="banner-dark-center-content">
                    <p class="banner-dark-title title-bold">Inicia ahora con nosotros como
                        <mark class="white"> tus socios estratégicos</mark>
                    </p>
                    <button (click)="goContactPage()" class="banner-dark-button button-size">
                        Contáctanos
                    </button>
                </div>
                <img class="banner-dark-img-right" src="./../../../assets/img/banner-dark/lines-banner-right.png"
                    alt="">
            </div>
        </div>

    </div>

</div>
