<div class="blog-container">
    <img class="blog-img" src="./../../../../assets/img/three-line-left.png" />
    <div class="blog-content">
        <div class="blog-info">
            <p class="solution-title title-bold">#BlogTeggium </p>
            <p class="subtitle-normal">Revisa nuestro blog para estar al día con lo último en el sector asegurador.</p>
            <button class="blog-button button-size-second" (click)="goToActualidad()">Leer más</button>
        </div>
        <div class="blog-carousel">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div (click)="goToActualidadFromBlog(1)">
                        <div class="team_card">
                            <figure>
                                <img src="./../../../../assets/img/impact.png" alt="img-carousel">
                            </figure>
                            <p class="team_card_label">TECNOLOGÍA</p>
                            <p class="team_card_text">El impacto de las tendencias tecnológicas en el éxito de las aseguradoras</p>
                            <div class="team_card_detail">
                                <img class="logo-img" src="../../../assets/img/iso_bg.png" alt="">
                                <p>EQUIPO TEGGIUM - 15 SEPT, 2023</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div (click)="goToActualidadFromBlog(2)">
                        <div class="team_card">
                            <figure>
                                <img src="./../../../../assets/img/blog/blog2.png" alt="img-carousel">
                            </figure>
                            <p class="team_card_label">SEGUROS DE HOGAR</p>
                            <p class="team_card_text">La importancia de los seguros en caso de sismo</p>
                            <div class="team_card_detail">
                                <img class="logo-img" src="../../../assets/img/iso_bg.png" alt="">
                                <p>EQUIPO TEGGIUM - 15 SEPT, 2023</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div (click)="goToActualidadFromBlog(3)">
                        <div class="team_card">
                            <figure>
                                <img src="./../../../../assets/img/blog3.1.png" alt="img-carousel">
                            </figure>
                            <p class="team_card_label">TECNOLOGÍA</p>
                            <p class="team_card_text">Teggium: Nuestra tecnología como aliada de la eficiencia operativa</p>
                            <div class="team_card_detail">
                                <img class="logo-img" src="../../../assets/img/iso_bg.png" alt="">
                                <p>Andrea Plaza - Directora de Estrategia Comercial - 21 DIC, 2023</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div (click)="goToActualidadFromBlog(4)">
                        <div class="team_card">
                            <figure>
                                <img src="./../../../../assets/img/blog/blog4-card.png" alt="img-carousel">
                            </figure>
                            <p class="team_card_label">TECNOLOGÍA</p>
                            <p class="team_card_text">Optimizamos la eficiencia en el sector asegurador: el poder de las reglas de negocio</p>
                            <div class="team_card_detail">
                                <img class="logo-img" src="../../../assets/img/iso_bg.png" alt="">
                                <p>Beatriz Corona - Directora de Operaciones - 12 ENE, 2024</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>