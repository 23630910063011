<app-inicio-carousel id="inicio"></app-inicio-carousel>

<app-inicio-descubre></app-inicio-descubre>

<div style="background-color: white;height: 50px;"></div>

<app-inicio-solution id="soluciones"></app-inicio-solution>

<app-inicio-estrategia id="socios"></app-inicio-estrategia>

<app-inicio-taps id="taps"></app-inicio-taps>

<app-inicio-purpose></app-inicio-purpose>

<app-inicio-numbers id="indicadores"></app-inicio-numbers>

<app-inicio-cobertura id="cobertura"></app-inicio-cobertura>

<app-inicio-blog></app-inicio-blog>

<app-banner-black></app-banner-black>

