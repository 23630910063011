import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tecnologia-banner',
  templateUrl: './tecnologia-banner.component.html',
  styleUrls: ['./tecnologia-banner.component.scss']
})
export class TecnologiaBannerComponent {

  public router = inject(Router)

  goContactPage() {
    this.router.navigateByUrl('/contacto');
  }
}
