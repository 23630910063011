import { Component, ViewChild, ElementRef, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email/email.service';
import Swal from 'sweetalert2';

export interface talentRequestI {
  name: string,
  lastname: string,
  mail: string,
  phone: string,
  file: File
}


@Component({
  selector: 'app-talento-container',
  templateUrl: './talento-container.component.html',
  styleUrls: ['./talento-container.component.scss']
})
export class TalentoContainerComponent {

  loading = false;
  colorTextInvalid = false

  title: string = 'Ningún archivo seleccionado'
  @ViewChild('file', { static: true }) fileInput!: ElementRef;
  fileToUpload!: File;

  public emailService = inject(EmailService)
  // talentForm!: FormGroup;

  handleFileInput(event: any) {
    const fileList: FileList = event.target.files;
    console.log(fileList[0]);

    if (fileList.length > 0) {
      if (fileList[0].size >= 3000000 || fileList[0].type !== 'application/pdf' && fileList[0].type !== 'image/jpeg' && fileList[0].type !== 'image/png' && fileList[0].type !== 'image/jpg' && fileList[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && fileList[0].type !== 'image/tiff' && fileList[0].type !== 'image/tif') {
        this.title = 'Formato incorrecto o el peso excede los 3mb'
        this.talentForm.get('filecv')?.setValue('')
        this.colorTextInvalid = true
        return
      }

      const file: File = fileList[0];
      this.fileToUpload = file;
      // console.log("hwhat is this", this.fileToUpload)
      this.title = this.fileToUpload.name
      this.colorTextInvalid = false
    }
  }

  talentForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(4)]),
    lastname: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('[- +()0-9]+')]),
    mail: new FormControl('', [Validators.required, Validators.email]),
    filecv: new FormControl('', [Validators.required])
  })

  get nameForm() {
    return this.talentForm.get('name');
  }

  onSubmit() {
    this.emailService.display(true)
    // TODO: Use EventEmitter with form value
    console.warn(this.talentForm.value);
    delete this.talentForm.value.filecv
    const { name, lastname, mail, phone } = this.talentForm.value
    let dataTalentContact: talentRequestI = {
      name: name!,
      lastname: lastname!,
      mail: mail!,
      phone: phone!,
      file: this.fileToUpload
    }
    this.emailService.sendTalentContact(dataTalentContact).subscribe({
      next: (v: any) => {
        // console.log(v)
        if (v.success) {
          this.showModal( "success","Mensaje enviado con éxito")
        }else{
          this.showModal( "info","Error en el envío, te invitamos a reenviar la comunicación")
        }
      },
      error: (e) => {
        this.showModal( "info","Error en el envío, te invitamos a reenviar la comunicación")
        this.talentForm.reset()
        this.emailService.display(false)
        this.title = 'Ningún archivo seleccionado'
      },
      complete: () => {
        this.talentForm.reset()
        this.emailService.display(false)
        this.title = 'Ningún archivo seleccionado'
      }
    }
    )
  }

  showModal(icon:string,texto:string){
    Swal.fire(Object.assign({
      text: texto,
      icon: icon,
      confirmButtonText: "Aceptar",
      showCloseButton: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    },));
  }
}
