<div class="estrategia-container">
    <div class="estrategia-content">
        <img class="estrategia-img visible-img" src="./../../../../assets/img/three-line-left.png" />
        <div class="estrategia-info">
            <img class="estrategia-img hide" src="./../../../../assets/img/three-line-left.png" />
            <p class="solution-title title-bold">Nuestro propósito</p>
            <p class="subtitle-normal">
                Integrar el ecosistema asegurador mediante el uso de la tecnología facilitando la transición hacia un modelo de excelencia operativa.
            </p>
        </div>
        <div class="purpose-images">
            <img src="../../../assets/img/arrow-blue.png" alt="arrow_blue"  class="hide">
            <img src="../../../assets/img/Group120.png" alt="img_purpose">
        </div>
    </div>
</div>