import { Component } from '@angular/core';

@Component({
  selector: 'app-inicio-purpose',
  templateUrl: './inicio-purpose.component.html',
  styleUrls: ['./inicio-purpose.component.scss']
})
export class InicioPurposeComponent {

}
