export const environment = {

    //PRUEBA
    // urlNotifications: "https://5slvlgfl-4010.use2.devtunnels.ms/api/notificaciones"

    //DEV
    // urlNotifications: "http://200.57.156.228:5040/api/notificaciones"

    //QA
    urlNotifications: "https://presto.teggium.com:5042/api/notificaciones"
    
};
// 