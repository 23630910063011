import { Component } from '@angular/core';
import { CountUpOptions } from 'countup.js';
// import counterUp from 'counterup2';

@Component({
  selector: 'app-inicio-numbers',
  templateUrl: './inicio-numbers.component.html',
  styleUrls: ['./inicio-numbers.component.scss']
})
export class InicioNumbersComponent {
  number1: number = 0;
  number2: number = 0;
  number3: number = 0
  number4: number = 0
  number5: number = 0
  stopNumber1: any;
  stopNumber2: any;
  stopNumber3: any;
  stopNumber4: any;
  stopNumber5: any;

  ngOnInit(): void {
    const el1 = document.querySelector('.container-counter');
    const IO = new IntersectionObserver(this.callback, { threshold: 1 });
    IO.observe(el1!);
  }

  callback = (entries: any[]) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting){
        if (this.number1 === 0) {
            this.stopNumber1 = setInterval(() => {
              this.number1++;
              if (this.number1 === 400) { clearInterval(this.stopNumber1);}
            }, 5.7)
        }
  
        if (this.number2 === 0) {
            this.stopNumber2 = setInterval(() => {
              this.number2++;
              if (this.number2 === 150) { clearInterval(this.stopNumber2);}
            }, 12)
        }
  
        if (this.number3 === 0) {
            this.stopNumber3 = setInterval(() => {
              this.number3++;
              if (this.number3 === 30) { clearInterval(this.stopNumber3);}
            },60)
        }
  
        if (this.number4 === 0) {
            this.stopNumber4 = setInterval(() => {
              this.number4++;
              if (this.number4 === 97) { clearInterval(this.stopNumber4);}
            }, 19)
        }
  
        if (this.number5 === 0) {
            this.stopNumber5 = setInterval(() => {
              this.number5++;
              if (this.number5 === 95) { clearInterval(this.stopNumber5);}
            }, 19)
        }
      }
    });
  };

  callback2 = (entries: any[]) => {
    // console.log("ver si se escapa memoria")
    entries.forEach((entry) => {
      if (this.number2 === 0) {
        if (entry.isIntersecting) {
          // console.log("solo debe entrar aqui una vez", entries)
          this.stopNumber2 = setInterval(() => {
            this.number2++;
            if (this.number2 === 152) {
              clearInterval(this.stopNumber2);
            }

          }, 12)
        }
      }
    });
  };

  callback3 = (entries: any[]) => {
    // console.log("ver si se escapa memoria")
    entries.forEach((entry) => {
      if (this.number3 === 0) {
        if (entry.isIntersecting) {
          // console.log("solo debe entrar aqui una vez", entries)
          this.stopNumber3 = setInterval(() => {
            this.number3++;
            if (this.number3 === 30) {
              clearInterval(this.stopNumber3);
            }

          }, 60)
        }
      }
    });
  };

  callback4 = (entries: any[]) => {
    // console.log("ver si se escapa memoria")
    entries.forEach((entry) => {
      if (this.number4 === 0) {
        if (entry.isIntersecting) {
          // console.log("solo debe entrar aqui una vez", entries)
          this.stopNumber4 = setInterval(() => {
            this.number4++;
            if (this.number4 === 95) {
              clearInterval(this.stopNumber4);
            }

          }, 19)
        }
      }
    });
  };

  callback5 = (entries: any[]) => {
    // console.log("ver si se escapa memoria")
    entries.forEach((entry) => {
      if (this.number5 === 0) {
        if (entry.isIntersecting) {
          // console.log("solo debe entrar aqui una vez", entries)
          this.stopNumber5 = setInterval(() => {
            this.number5++;
            if (this.number5 === 95) {
              clearInterval(this.stopNumber5);
            }

          }, 19)
        }
      }
    });
  };



}
