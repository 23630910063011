<div class="banner-dark-container">
    <div class="banner-dark-content">
        <img class="banner-dark-img-left" src="./../../../assets/img/banner-dark/lines-banner-left.png" alt="">
        <div class="banner-dark-center-content">
            <p class="banner-dark-title title-bold">Inicia ahora con nosotros como
                <mark class="white"> tus socios estratégicos</mark>
            </p>
            <button (click)="goContactPage()" class="banner-dark-button button-size">
                Contáctanos
            </button> 
        </div>
        <img class="banner-dark-img-right" src="./../../../assets/img/banner-dark/lines-banner-right.png" alt="">
    </div>
</div>
