import { NgModule } from '@angular/core';
import { NavigationEnd, RouterModule, Routes } from '@angular/router';
import { InicioPageComponent } from './pages/inicio-page/inicio-page.component';
import { TecnologiaPageComponent } from './pages/tecnologia-page/tecnologia-page.component';
import { TalentoPageComponent } from './pages/talento-page/talento-page.component';
import { ActualidadPageComponent } from './pages/actualidad-page/actualidad-page.component';
import { ContactoPageComponent } from './pages/contacto-page/contacto-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { InicioEstrategiaComponent } from './components/inicio/inicio-estrategia/inicio-estrategia.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';

const routes: Routes = [
  {
    path: 'inicio',
    component: InicioPageComponent,
    title: 'Inicio - Teggium'
  },
  {
    path: 'tecnologia',
    component: TecnologiaPageComponent,
    title: 'Tecnología - Teggium'
  },
  {
    path: 'talento',
    component: TalentoPageComponent,
    title: 'Talento - Teggium'
  },
  {
    path: 'actualidad',
    component: ActualidadPageComponent,
    title: 'Actualidad - Teggium'
  },
  {
    path: 'contacto',
    component: ContactoPageComponent,
    title: 'Contacto - Teggium'
  },
  {
    path: 'blog/:id',
    component: BlogDetailComponent,
  },
  {
    path: '',
    redirectTo: '/inicio', 
    pathMatch: 'full'
  },
  {
    path: '**', pathMatch: 'full',
    component: ErrorPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
