import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent {
  public router = inject(Router)
  public activatedRouter = inject(ActivatedRoute)
  numberBlog = 0

  ngOnInit(){
    this.activatedRouter.paramMap.subscribe((params:any) => {
      this.numberBlog = parseInt(params.params.id)
   });
  }

  public array1 = [
    {
      detail: 'EQUIPO TEGGIUM - 15 SEPT, 2023',
      text: 'El impacto de las tendencias tecnológicas en el éxito de las aseguradoras',
      label: 'TECNOLOGÍA',
      number: 1,
      img: "./../../../../assets/img/impact.png"
    },
    {
      detail: 'Andrea Plaza - Directora de Estrategia Comercial - 21 DIC, 2023',
      text: 'Teggium: Nuestra tecnología como aliada de la eficiencia operativa ',
      label: 'TECNOLOGÍA',
      number: 3,
      img: "./../../../../assets/img/blog3.1.png"
    },
    {
      detail: 'Beatriz Corona - Directora de Operaciones - 12 ENE, 2024',
      text: 'Optimizamos la eficiencia en el sector asegurador: el poder de las reglas de negocio',
      label: 'TECNOLOGÍA',
      number: 4,
      img: "./../../../../assets/img/blog/blog4-card.png"
    }
  ]

  goContactPage() {
    this.router.navigateByUrl('/contacto');
  }

  verBlog(numero: number){

    console.log("number selected",numero)
    this.router.navigateByUrl(`/blog/${numero}`)
  }

  returnBlogs(){
    this.router.navigateByUrl('/actualidad')
  }



}
