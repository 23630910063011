import { Component } from '@angular/core';

@Component({
  selector: 'app-contacto-container',
  templateUrl: './contacto-container.component.html',
  styleUrls: ['./contacto-container.component.scss']
})
export class ContactoContainerComponent {

  ngAfterViewInit() {
    this.goToTop()
  }

  goToTop(){
    document.querySelector("#contacto")!.scrollIntoView();
  }
}
