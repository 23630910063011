import { Component, inject, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/services/routes/routes.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input () valueMenu!: boolean;

  public router = inject(Router);

  public urlComponent = inject(RoutesService);

  @Output() openSide = new EventEmitter();

  @Output() closeByLogo = new EventEmitter();

  isOpen: boolean = false;
  
  constructor(){}

  goContactPage(){
    this.router.navigateByUrl('/contacto')
  }

  goInicio(){
    if(this.router.url === '/inicio'){
      document.getElementById("inicio")!.scrollIntoView({behavior:'smooth'});
    } else {
      this.urlComponent.setComponentName('inicio');
      this.router.navigateByUrl('/inicio')
    }
    this.closeByLogo.emit(false);
  }


  ScrollPage(elem: string){
    if(this.router.url === '/inicio'){
      setTimeout( () => {
        document.querySelector("#socios")!.scrollIntoView({behavior:'smooth'});
      }, 100)
    } else {
      this.urlComponent.setComponentName('socios');
    }
  }

  openSideBar() {
    this.valueMenu = !this.valueMenu
    this.openSide.emit(this.valueMenu);
  }
}
