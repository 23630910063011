<div class="talento-container">
    <img class="talento-lines d-none d-sm-block" src="./../../../../assets/img/talento/talento-lines.png" alt="">
    <div class="talento-content">
        <div class="talento-title-container">
            <div class="talento-content-info-border">
                <p class="talento-banner-title title-bold">Sé parte de nuestra comunidad Teggium</p>
                <div class="footer-borders-container">
                    <div class="container-left">
                        <div class="rombo-blue-left"></div>
                    </div>
                    <div  class="container-right">
                        <div class="rombo-blue-rigth"></div>
                    </div>
                </div>
            </div>
        </div>
        <img class="talento-logo-img d-block d-sm-none" src="./../../../../assets/img/talento/talento-logo-img.png" alt="">
        <div class="talento-logo-form-container">
            <div class="talento-content-info"> 
                <p class="talento-banner-subtitle subtitle-normal">Si cuentas con iniciativa, inclinación hacia la tecnología, experiencia en el mercado asegurador y te gustaría formar parte de la comunidad más grande de insurtech en Latinoamérica.</p>
                <img class="talento-logo-img d-none d-sm-block" src="./../../../../assets/img/talento/talento-logo-img.png" alt="">
            </div>
            <div class="talento-form-container" >
                <h3 class="talento-form-title">Déjanos tus datos de contacto y CV</h3>
                <form class="talento-form" [formGroup]="talentForm">
                    <div class="talento-item">
                        <input class="talento-input" type="text" placeholder="Nombre" formControlName="name" required>
                        <ng-container
                        *ngIf="!nameForm?.valid && (nameForm?.dirty ||nameForm?.touched)">
                          <p class="error-message">Por favor ingrese un nombre</p>
                        </ng-container>
                    </div>
                    <div class="talento-item">
                        <input class="talento-input" type="text" placeholder="Apellidos" formControlName="lastname">
                        <ng-container
                        *ngIf="!talentForm.controls.lastname?.valid && (talentForm.controls.lastname?.dirty ||talentForm.controls.lastname?.touched)">
                          <p class="error-message">Por favor ingrese sus apellidos</p>
                        </ng-container>
                    </div>
                    <div class="talento-item">
                        <input class="talento-input" type="text" placeholder="Teléfono" formControlName="phone">
                        <ng-container
                        *ngIf="!talentForm.controls.phone?.valid && (talentForm.controls.phone?.dirty ||talentForm.controls.phone?.touched)">
                          <p class="error-message">Por favor ingrese un teléfono</p>
                        </ng-container>
                    </div>
                    <div class="talento-item">
                        <input class="talento-input" type="email" placeholder="Correo electrónico" [email]="true" formControlName="mail">
                        <ng-container
                        *ngIf="!talentForm.controls.mail?.valid && (talentForm.controls.mail?.dirty ||talentForm.controls.mail?.touched)">
                          <p class="error-message">Por favor ingrese un correo</p>
                        </ng-container>
                    </div>
                    <div class="form-footer-container">
                            <div class="talento-upload-container">
                                <button class="talento-upload-button">Carga un archivo  <img src="./../../../../assets/img/icon-upload.png" alt=""></button>
                                <!-- accept="application/pdf" -->
                                <!-- image/gif, image/jpeg" -->
                                <input #file (change)="handleFileInput($event)" type="file" formControlName="filecv" accept=".pdf, .jpeg, .png, .jpg, .doc, .docx, .tiff, .tif "   style="width: 100%;" >
                                <!-- <p class="error-message">Por favor ingrese un correo</p> -->
                            </div>
                            <!-- d-none d-lg-block -->
                            <div class="talento-upload-selected-container  ">
                                <span class="talento-upload-selected" [ngClass]="{'error-message':colorTextInvalid}">{{title}}</span>
                            </div>
                            
                            <button class="talent-form-button button-size-second" type="submit" [disabled]="!talentForm.valid"  (click)="onSubmit()">
                                Enviar
                            </button> 
                    </div>
    
                </form>
            </div>
        </div>
        

    </div>

</div>



<!-- Email Template -->

<!-- <div style="display: flex; justify-content: center; align-items: center; font-family: Instrument Sans, sans-serif;">
    <div style="width: 640px;">
        <div style="background:#0f0f0f; display: flex; padding: 12px 0px; justify-content: center; align-items: center;">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="30" viewBox="0 0 148 39" fill="none">
                    <path d="M0 -0.000430107L0 10.4902L28.0051 10.4902V-0.000430107L0 -0.000430107Z" fill="#D1D2D4"/>
                    <path d="M19.2514 21.3711H8.76074V31.8618H19.2514V21.3711Z" fill="#D1D2D4"/>
                    <path d="M13.1206 10.982L9.05415 15.0484C8.56581 15.5367 8.56581 16.3285 9.05415 16.8168L13.1206 20.8833C13.6089 21.3716 14.4007 21.3716 14.889 20.8833L18.9554 16.8168C19.4438 16.3285 19.4438 15.5367 18.9554 15.0484L14.889 10.982C14.4007 10.4936 13.6089 10.4936 13.1206 10.982Z" fill="#6EE1EB"/>
                    <path d="M93.6063 6.03142L91.176 8.46173C90.8835 8.75415 90.8835 9.22827 91.176 9.52069L93.6063 11.951C93.8987 12.2434 94.3728 12.2434 94.6652 11.951L97.0956 9.52069C97.388 9.22827 97.388 8.75416 97.0956 8.46173L94.6652 6.03142C94.3728 5.73899 93.8987 5.73899 93.6063 6.03142Z" fill="#6EE1EB"/>
                    <path d="M35.2984 32.2587C29.9595 32.2587 26.0059 29.0014 26.0059 23.2955C26.0059 17.5897 30.1392 13.8232 35.1861 13.8232C40.6374 13.8232 43.9246 17.41 43.9246 22.1274C43.9246 23.0409 43.8497 24.5011 43.8497 24.5011H30.8281C31.2699 27.3166 33.4265 28.6719 36.0922 28.6719C38.4659 28.6719 40.5925 27.6086 41.4311 26.8823L43.2582 29.4057C42.2698 30.4316 39.604 32.2587 35.2835 32.2587H35.2984ZM35.1487 17.3052C32.3706 17.3052 30.903 19.2445 30.7607 21.476H39.2446C39.1697 19.5366 38.4434 17.3052 35.1487 17.3052Z" fill="#D1D2D4"/>
                    <path d="M62.652 35.8451C61.1169 37.455 58.8481 38.9526 54.6099 38.9526C50.3717 38.9526 47.2567 36.9383 46.3806 36.3168L48.32 33.0595C49.4507 33.8608 52.341 35.1412 54.6099 35.1412C57.1333 35.1412 58.4512 34.34 59.1102 33.5687C59.7691 32.7975 60.3157 31.3373 60.3157 29.3605V28.4469C59.0578 30.304 56.9162 31.2624 54.3179 31.2624C49.8176 31.2624 45.9463 28.3721 45.9463 22.9208C45.9463 17.9114 49.271 13.8154 54.6473 13.8154C57.021 13.8154 59.0727 14.6915 60.3532 16.6684C60.3157 15.8671 60.3157 14.8038 60.3157 14.3246V14.1449H64.9583V28.1849C64.9583 32.0636 64.1571 34.2576 62.652 35.8301V35.8451ZM60.5703 22.6063C60.5703 20.1203 58.6684 17.522 55.486 17.522C52.5207 17.522 50.6937 19.8283 50.6937 22.6063C50.6937 25.3844 52.4459 27.6158 55.6283 27.6158C58.8106 27.6158 60.5628 25.3844 60.5628 22.6063H60.5703Z" fill="#D1D2D4"/>
                    <path d="M84.9586 35.8451C83.4236 37.455 81.1547 38.9526 76.9165 38.9526C72.6783 38.9526 69.5633 36.9383 68.6872 36.3168L70.6266 33.0595C71.7573 33.8608 74.6477 35.1412 76.9165 35.1412C79.44 35.1412 80.7579 34.34 81.4168 33.5687C82.0758 32.7975 82.6224 31.3373 82.6224 29.3605V28.4469C81.3644 30.304 79.2228 31.2624 76.6245 31.2624C72.1242 31.2624 68.2529 28.3721 68.2529 22.9208C68.2529 17.9114 71.5776 13.8154 76.954 13.8154C79.3277 13.8154 81.3794 14.6915 82.6598 16.6684C82.6224 15.8671 82.6224 14.8038 82.6224 14.3246V14.1449H87.2649V28.1849C87.2649 32.0636 86.4637 34.2576 84.9586 35.8301V35.8451ZM82.877 22.6063C82.877 20.1203 80.975 17.522 77.7926 17.522C74.8274 17.522 73.0003 19.8283 73.0003 22.6063C73.0003 25.3844 74.7525 27.6158 77.9349 27.6158C81.1173 27.6158 82.8695 25.3844 82.8695 22.6063H82.877Z" fill="#D1D2D4"/>
                    <path d="M96.4759 14.1523V31.8539H91.7959V14.1523H96.4759Z" fill="#D1D2D4"/>
                    <path d="M106.936 32.1834C104.787 32.1834 103.319 31.5919 102.346 30.5885C101.02 29.2257 100.608 27.0916 100.608 24.6505V14.1523H105.288V23.6246C105.288 26.2604 105.73 28.3421 108.433 28.3421C111.137 28.3421 112.312 26.1107 112.312 23.1454V14.1523H116.992V31.8539H112.454L112.417 29.3679C111.391 31.1201 109.489 32.1834 106.928 32.1834H106.936Z" fill="#D1D2D4"/>
                    <path d="M125.986 14.1527L125.948 16.8259H126.061C126.862 15.1786 129.026 13.8232 131.325 13.8232C133.885 13.8232 135.421 14.8491 136.259 17.1853C137.502 15.0288 139.516 13.8232 142.077 13.8232C146.72 13.8232 148 17.118 148 21.1764V31.8543H143.32V21.9402C143.32 19.7837 142.841 17.6646 140.542 17.6646C138.243 17.6646 136.993 19.6788 136.993 22.7115V31.8543H132.313V21.9103C132.313 19.4991 131.691 17.6646 129.46 17.6646C127.049 17.6646 125.986 19.9334 125.986 22.7115V31.8543H121.306V14.1527H125.986Z" fill="#D1D2D4"/>
                </svg>
            </div>
        </div>
        <div style="background: linear-gradient(180deg, rgba(110, 225, 235, 0.00) 32.87%, #6EE1EB 106.81%); padding: 20px 40px;">
            <div style="background-color: #ffffff; display: flex; border-radius: 15px; padding: 30px 24px; flex-direction: column; justify-content: center; align-items: center; gap: 24px;
            box-shadow: -1px -5px 12px 0px rgba(0,0,0,0.07); -webkit-box-shadow: -1px -5px 12px 0px rgba(0,0,0,0.07); -moz-box-shadow: -1px -5px 12px 0px rgba(0,0,0,0.07);">
                <div style="display: grid; gap: 24px;">
                    <div style="text-align: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 32 32" fill="none">
                            <path d="M5.05033 29.8998L6.52197 29.5059L6.91684 28.6506L9.46731 28.7176L12.1198 28.0076L12.7172 27.0814L15.1991 27.1834L17.7904 26.4898C19.5904 26.0098 20.2004 23.7498 18.8804 22.4298L9.56033 13.0998C8.24033 11.7798 5.98033 12.3798 5.50033 14.1898L4.72771 17.0764L4.83587 19.421L3.95508 19.963L3.16519 22.9141L3.29754 25.1619L2.43407 25.6456L2.09033 26.9298C1.60033 28.7298 3.25033 30.3798 5.05033 29.8998Z" fill="#F9C23C"/>
                            <path d="M7.80085 25.0526C6.32053 23.6703 5.05964 22.006 3.94629 19.995L4.72363 17.0908C6.0021 19.8887 7.44024 21.98 9.16547 23.591C10.8282 25.1437 12.7977 26.2878 15.2359 27.1736L12.1067 28.0112C10.5048 27.2141 9.08005 26.2472 7.80085 25.0526Z" fill="#3F5FFF"/>
                            <path d="M4.28669 27.8864C3.57902 27.2246 2.97068 26.4834 2.43164 25.6525L3.1765 22.8696C3.87916 24.3598 4.6662 25.5036 5.6524 26.4258C6.63626 27.3459 7.86083 28.0852 9.49053 28.7115L6.50173 29.5115C5.67896 29.0389 4.946 28.503 4.28669 27.8864Z" fill="#3F5FFF"/>
                            <path d="M19.1096 25.6899C20.5096 24.2899 18.7696 20.2899 15.2296 16.7499C11.6896 13.2099 7.6996 11.4699 6.2996 12.8699C4.8996 14.2699 6.6396 18.2699 10.1796 21.8099C13.7196 25.3499 17.7196 27.0799 19.1096 25.6899Z" fill="#6D4534"/>
                            <path d="M11.6099 7.27028C12.8499 9.59028 13.0999 12.2502 12.3299 14.7602C11.6799 16.9002 10.3399 18.7102 8.51992 19.9602C7.99992 19.3102 7.54992 18.6602 7.16992 18.0302C8.55992 17.0802 9.57992 15.7002 10.0799 14.0702C10.6699 12.1602 10.4799 10.1402 9.53992 8.38028C9.22992 7.80028 9.44992 7.09028 10.0199 6.79028C10.5999 6.48028 11.3099 6.70028 11.6099 7.27028Z" fill="#F70A8D"/>
                            <path d="M22.1502 16.7126C24.0409 15.219 26.4931 14.9149 28.6041 15.7022C29.1465 15.9045 29.7503 15.6288 29.9526 15.0863C30.1549 14.5439 29.8791 13.9402 29.3367 13.7379C26.5677 12.7052 23.3399 13.1011 20.8506 15.0675C20.3963 15.4263 20.319 16.0855 20.6779 16.5398C21.0367 16.9941 21.6959 17.0715 22.1502 16.7126Z" fill="#F70A8D"/>
                            <path d="M6.00961 9.69012C6.65026 9.69012 7.16961 9.17077 7.16961 8.53012C7.16961 7.88947 6.65026 7.37012 6.00961 7.37012C5.36896 7.37012 4.84961 7.88947 4.84961 8.53012C4.84961 9.17077 5.36896 9.69012 6.00961 9.69012Z" fill="#F70A8D"/>
                            <path d="M26.1296 8.53012C26.1296 9.17077 25.6102 9.69012 24.9696 9.69012C24.3289 9.69012 23.8096 9.17077 23.8096 8.53012C23.8096 7.88947 24.3289 7.37012 24.9696 7.37012C25.6102 7.37012 26.1296 7.88947 26.1296 8.53012Z" fill="#F70A8D"/>
                            <path d="M12.4903 3.96C12.4903 4.49019 12.0605 4.92 11.5303 4.92C11.0001 4.92 10.5703 4.49019 10.5703 3.96C10.5703 3.42981 11.0001 3 11.5303 3C12.0605 3 12.4903 3.42981 12.4903 3.96Z" fill="#F9C23C"/>
                            <path d="M25.48 24.4099C25.48 24.9456 25.0457 25.3799 24.51 25.3799C23.9743 25.3799 23.54 24.9456 23.54 24.4099C23.54 23.8742 23.9743 23.4399 24.51 23.4399C25.0457 23.4399 25.48 23.8742 25.48 24.4099Z" fill="#F9C23C"/>
                            <path d="M21.6302 4.01987C21.4702 3.87987 21.2702 3.78987 21.0502 3.77987C20.5102 3.74987 20.0502 4.15987 20.0102 4.69987C19.9402 5.79987 19.1802 7.78987 18.8902 8.45987C18.8902 8.46487 18.8877 8.46987 18.8852 8.47487C18.8827 8.47987 18.8802 8.48487 18.8802 8.48987C18.8266 8.62193 18.7693 8.75216 18.7083 8.88035C18.6063 8.7526 18.4936 8.63567 18.3702 8.52988C18.0902 8.27988 17.7502 8.08988 17.3502 7.95988L17.3202 7.94988C15.9702 7.49988 14.5002 8.22988 14.0502 9.58988C13.8302 10.2499 13.8802 10.9499 14.1902 11.5699C14.5002 12.1899 15.0302 12.6499 15.6902 12.8699C15.761 12.8935 15.8329 12.9085 15.9046 12.9153C16.4098 13.0518 16.8988 13.0565 17.3695 12.9301C17.341 13.0674 17.3079 13.204 17.2702 13.3399C17.1302 13.8599 17.4402 14.3999 17.9602 14.5399C18.4802 14.6799 19.0202 14.3699 19.1602 13.8499C19.3855 13.0353 19.4831 12.2057 19.4531 11.3676C19.9734 10.7158 20.389 9.99768 20.7002 9.21987L20.7149 9.18423C20.9003 8.73562 21.8731 6.38194 21.9702 4.80987C21.9802 4.49987 21.8502 4.20987 21.6302 4.01987ZM16.7502 9.80988C17.0256 9.90168 17.3079 10.0562 17.4287 10.686C16.9362 11.1434 16.6202 11.0932 16.3402 10.9999C16.3065 10.9887 16.2726 10.9794 16.2386 10.972C16.1116 10.9123 16.0094 10.8069 15.9502 10.6799C15.8702 10.5299 15.8602 10.3599 15.9202 10.1999C16.0302 9.86988 16.3902 9.68988 16.7202 9.79988L16.7502 9.80988Z" fill="#00A6ED"/>
                            <path d="M18.2701 18.1599C21.6801 17.2099 25.2501 17.6399 28.3301 19.3799C28.9001 19.7099 29.1001 20.4199 28.7901 20.9799C28.4701 21.5499 27.7501 21.7499 27.1801 21.4299C24.6501 20.0099 21.7101 19.6499 18.9101 20.4299C16.6001 21.0699 14.6201 22.4299 13.1901 24.3099C12.5801 23.8899 11.9501 23.4099 11.3301 22.8699C13.0601 20.5999 15.4701 18.9399 18.2701 18.1599Z" fill="#00A6ED"/>
                            <path d="M16.6242 16.9854C16.6242 17.6206 16.1093 18.1354 15.4742 18.1354C14.8391 18.1354 14.3242 17.6206 14.3242 16.9854C14.3242 16.3503 14.8391 15.8354 15.4742 15.8354C16.1093 15.8354 16.6242 16.3503 16.6242 16.9854Z" fill="#00A6ED"/>
                        </svg>
                    </div>
                    <p style="color:#00BCD4; font-size: 18px; font-style: normal; font-weight: 600; line-height: normal; margin: 0;">
                        ¡Muchas gracias por querer pertenecer a Teggium! </p>
                </div>
                <div style="display: grid; gap: 24px;">
                    <p style="color: #212429; font-size: 16px; font-style: normal; font-weight: 400; line-height: normal; margin: 0;">
                        Agradecemos el entusiasmo por formar parte de la comunidad más grande de insurtech en Latinoamérica. Estaremos revisando los archivos adjuntados y nos comunicaremos en caso alguna posición encaje con tus cualidades.
                    </p>
                    <div style="display: grid; gap: 10px; color: #212429; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; letter-spacing: 0.027px;">
                        <p style="margin: 0;">
                            Lo invitamos a seguirnos en nuestro <a href="https://www.linkedin.com/company/teggium/" target='_blank' rel="noreferrer" style="color: #002C77;">LinkedIn</a> y mantenerse al tanto de las novedades en <a href="https://www.teggium.com" target="_blank" rel="noreferrer" style="color: #002C77;">www.teggium.com</a>
                        </p>
                        <p style="margin: 0;">Saludos cordiales</p>
                    </div>
                </div>
            </div>
        </div>
        <div style="background:#0f0f0f; display: flex; padding: 12px 0px; justify-content: center; align-items: center;">
            <p style="margin: 0; color: #FFFFFF; font-family: 'Source Sans 3'; font-size: 12px; font-style: normal;font-weight: 400; line-height: normal;">
                Por favor, no responda a este correo electrónico</p>
        </div>
        <div style="background: #6EE1EB; padding: 10px 24px 16px;">
            <div style="color: #000000; text-align: center; font-family: 'Source Sans 3'; font-size: 10px; font-style: normal; font-weight: 400; line-height: normal;">
                <p style="margin: 0;"> Copyright © 2023 Teggium. Todos los derechos reservados. </p>
                <p style="margin: 0;"> <a href="#" style="color: #000000;">www.teggium.com</a> </p>
            </div>
        </div>
    </div>
</div> -->