import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-actualidad-container',
  templateUrl: './actualidad-container.component.html',
  styleUrls: ['./actualidad-container.component.scss']
})
export class ActualidadContainerComponent {
  variable:any


  constructor(private viewportScroller: ViewportScroller,private router: Router) {
  }

  ngOnInit(){
  }

  ngAfterViewInit() {
    this.goToTop()
  }

  goToTop(){
    document.querySelector("#actualidad")!.scrollIntoView();
  }

  public array1 = [
    {
      detail: 'EQUIPO TEGGIUM - 15 SEPT, 2023',
      text: 'El impacto de las tendencias tecnológicas en el éxito de las aseguradoras',
      label: 'TECNOLOGÍA',
      number: 1,
      img: "./../../../../assets/img/impact.png"
    },
    {
      detail: 'Andrea Plaza - Directora de Estrategia Comercial - 21 DIC, 2023',
      text: 'Teggium: Nuestra tecnología como aliada de la eficiencia operativa ',
      label: 'TECNOLOGÍA',
      number: 3,
      img: "./../../../../assets/img/blog3.1.png"
    },
    {
      detail: 'Beatriz Corona - Directora de Operaciones - 12 ENE, 2024',
      text: 'Optimizamos la eficiencia en el sector asegurador: el poder de las reglas de negocio',
      label: 'TECNOLOGÍA',
      number: 4,
      img: "./../../../../assets/img/blog/blog4-card.png"
    }
  ]

  public array2 = [
    {
      detail: 'EQUIPO TEGGIUM - 15 SEPT, 2023',
      text: 'El impacto de las tendencias tecnológicas en el éxito de las aseguradoras',
      label: 'TECNOLOGÍA',
      number: 1,
      img: "./../../../../assets/img/impact.png"
    },
    {
      detail: 'EQUIPO TEGGIUM - 15 SEPT, 2023',
      text: 'La importancia de los seguros en caso de sismo',
      label: 'SEGUROS DE HOGAR',
      number: 2,
      img: "./../../../../assets/img/blog/blog2.png"
    },
    {
      detail: 'EQUIPO TEGGIUM - 22 DIC, 2023',
      text: 'Nuestra tecnología como aliada de la eficiencia operativa',
      label: 'TECNOLOGÍA',
      number: 3,
      img: "./../../../../assets/img/blog/blog3.jpg"
    },
    {
      detail: 'EQUIPO TEGGIUM - 15 SEPT, 2023',
      text: 'La importancia de los seguros en caso de sismo',
      label: 'SEGUROS DE HOGAR',
      number: 2,
      img: "./../../../../assets/img/blog/blog2.png"
    },
    {
      detail: 'EQUIPO TEGGIUM - 15 SEPT, 2023',
      text: 'El impacto de las tendencias tecnológicas en el éxito de las aseguradoras',
      label: 'TECNOLOGÍA',
      number: 1,
      img: "./../../../../assets/img/impact.png"
    },
    {
      detail: 'EQUIPO TEGGIUM - 22 DIC, 2023',
      text: 'Nuestra tecnología como aliada de la eficiencia operativa',
      label: 'TECNOLOGÍA',
      number: 3,
      img: "./../../../../assets/img/blog/blog3.jpg"
    },
  ]

  verBlog(numero: number){
    this.router.navigateByUrl(`/blog/${numero}`)
  }


  goContactPage() {
    this.router.navigateByUrl('/contacto');
  }

}
