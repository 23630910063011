import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { talentRequestI } from 'src/app/components/talento/talento-container/talento-container.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  display(value: boolean) {
    this.status.next(value);
}



  constructor(private http:HttpClient) { }

  sendTalentContact({name,lastname,phone,mail,file}:talentRequestI){
    let formData = new FormData();
    formData.append('nombre',name)
    formData.append('apellidos',lastname)
    formData.append('email',mail)
    formData.append('telefono',phone)
    formData.append('file', file);
    const url = `${environment.urlNotifications}/nuevo-talento`
    return this.http.post(url,formData)
  }
}
