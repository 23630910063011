<div class="tecnologia-plataforma-container">
    <img class="arrow-skyblue-left" src="./../../../assets/img/arrow-blue.png" alt="">
    <div class="tecnologia-plataforma-content">
        <h2 class="tecnologia-plataforma-title  title-bold">Una sola plataforma, varias soluciones</h2>
        <!-- No eliminar aún -->
        <!-- <div class="container-big">
            <div class="plataformas-container">
                <div class="plataformas-content">
                    <div class="plataformas-clientes-entradas">
                        <div class="plataformas-clientes">
                            <div class="plataformas-title">
                                Clientes
                            </div>
                            <div class="item-cliente">
                                <div>
                                    <img src="./../../../../assets/img/tecnologia-plataforma/icono-asegurado.png" alt="">
                                </div>
                                Asegurado
                            </div>
                            <div class="item-cliente">
                                <div>
                                    <img src="./../../../../assets/img/tecnologia-plataforma/icono-aseguradora.png" alt="">
                                </div>
                                Aseguradora
                            </div>
                            <div class="item-cliente">
                                <div>
                                    <img src="./../../../../assets/img/tecnologia-plataforma/broker-icono.png" alt="">
    
                                </div>
                                Bróker
                            </div>
                            <div class="item-cliente">
                                <div>
                                    <img src="./../../../../assets/img/tecnologia-plataforma/prestadores-icono.png" alt="">
    
                                </div>
                                Prestadores de servicios
                            </div>
                        </div>
                        <div class="plataformas-entradas">
                            <div class="plataformas-entradas-title">
                                Entradas
                            </div>
                            <div class="item-entradas">
                                App
                            </div>
                            <div class="item-entradas">
                                Web
                            </div>
                            <div class="item-entradas">
                                Presto
                            </div>
                            <div class="item-entradas">
                                Otro
                            </div>
                        </div>
                    </div>
                    <div class="plataformas-flujos-funcionalidad">
                        <div class="plataformas-flujos">
                            <div class="plataformas-title">
                                Flujos
                            </div>
                            <div class="item-flujo item-flujo-black">
                                Emisión
                            </div>
                            <div class="item-flujo item-flujo-black">
                                Siniestros
                            </div>
                            <div class="item-flujo item-flujo-black">
                                Compliance
                            </div>
                            <div class="item-flujo">
                                Vida
                            </div>
                            <div class="item-flujo">
                                Vida
                            </div>
                            <div class="item-flujo">
                                KYC
                            </div>
                            <div class="item-flujo">
                                Daños
                            </div>
                            <div class="item-flujo">
                                Daños
                            </div>
                            <div class="item-flujo">
                                Agentes
                            </div>
                            <div class="item-flujo">
                                Salud
                            </div>
                            <div class="item-flujo">
                                Salud
                            </div>
                            <div class="item-flujo item-flujo-black">
                                Cuadre cobranza
                            </div>
                            <div class="item-flujo">
                                Auto flotillas
                            </div>
                            <div class="item-flujo">
                                Perdidas totales
                            </div>
                        </div>
                        <div class="plataformas-funcionalidad">
                            <div class="plataformas-title">
                                Funcionalidad
                            </div>
                            <div class="item-funcionalidad">
                                OCR
                            </div>
                            <div class="item-funcionalidad">
                                Motor de reglas
                            </div>
                            <div class="item-funcionalidad">
                                Mensajerias
                            </div>
                            <div class="item-funcionalidad">
                                Reportes
                            </div>
                            <div class="item-funcionalidad">
                                Notificaciones
                            </div>
                            <div class="item-funcionalidad">
                                Call Center
                            </div>
                            <div class="item-funcionalidad">
                                RPA
                            </div>
                            <div class="item-funcionalidad">
                                Creacion de documentos
                            </div>
                            <div class="item-funcionalidad">
                                Dashboards
                            </div>
                        </div>
    
                    </div>
                    <div class="plataformas-alianzas-integraciones">
                        <div class="plataformas-alianzas">
                            <div class="plataformas-title">
                                Alianzas(API)
                            </div>
                            <div class="item-alianzas">
                                Pasarelas de pagos
                            </div>
                            <div class="item-alianzas">
                                Herramientas de evaluacion
                            </div>
                            <div class="item-alianzas">
                                Marketplaces
                            </div>
                            <div class="item-alianzas">
                                After service
                            </div>
                            <div class="item-alianzas">
                                CRM
                            </div>
                        </div>
                        <div class="plataformas-integraciones">
                            <div class="plataformas-title">
                                Integraciones
                            </div>
                            <div class="item-integraciones">
                                Core
                            </div>
                            <div class="item-integraciones">
                                AI
                            </div>
                            <div class="item-integraciones">
                                Biometricos
                            </div>
                            <div class="item-integraciones">
                                Chatbot
                            </div>
                            <div class="item-integraciones">
                                Firmas Digitales
                            </div>
                            <div class="item-integraciones">
                                Content Manager
                            </div>
                        </div>
                    </div>
                </div>
                <div class="infraestructura-content">
                    <div class="plataformas-title">
                        Infraestructura
                    </div>
                    <img src="./../../../../assets/img/tecnologia-plataforma/tecnologia-amazon.png" alt="">
                </div>
            </div>
        </div> -->
        <div class="row" style="row-gap: 30px; margin-top: 100px;">
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Abierta-Apificada.png" width="96%" draggable="false" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Multicanal.png" width="96%" draggable="false" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Flexible.png" width="96%" draggable="false" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Responsive.png" width="96%" draggable="false" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Nube.png" width="96%" draggable="false" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Trazabilidad.png" width="96%" draggable="false" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Gestion-documental.png" width="96%" draggable="false" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Reporteria.png" width="96%" draggable="false" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-4">
                <img src="./../../../../assets/img/tecnologia-plataforma/Automatizacion.png" width="96%" draggable="false" alt="">
            </div>
        </div>
    </div>
</div>
