import { Component } from '@angular/core';

@Component({
  selector: 'app-talento-page',
  templateUrl: './talento-page.component.html',
  styleUrls: ['./talento-page.component.scss']
})
export class TalentoPageComponent {
  ngAfterViewInit() {
    this.goToTop()
  }

  goToTop(){
    document.querySelector("#talento")!.scrollIntoView(true);
  }
}
