<section>
    <div class="row gx-0 footer_socialmedia">
        <div class="footer_socialmedia_container">
            <div class="align-left">
                <img src="assets/img/footer/t-footer.svg" alt="isotipo">
                <div class="footer_socialmedia_text">
                    <p>
                        Somos parte de Grupo BC
                    </p>
                    <strong>
                        <a href="https://www.grupobc.com/es/" target="_blank">www.grupobc.com</a>    
                    </strong>
                </div>
                <div class="footer_socialmedia_icons">
                    <div *ngFor="let item of media">
                        <a href={{item.url}} target="_blank">
                            <img src="{{item.icon}}" alt="media-icon">
                        </a>
                    </div>
                </div>

                <div [ngClass]="!isTablet ? 'd-none' : 'd-block' ">
                    <div class="footer_card">
                        <div class="footer_card_item"  *ngFor="let items of  menu_tablet">
                            <p class="footer_card_item_title">{{items.title}}</p>
                            <ul class="footer_card_item_list">
                                <li  *ngFor="let item of items.sections">
                                    <span (click)="selectFooter(item)" style="cursor:pointer;">
                                        {{item.name}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="align-pd">
                <div class="footer_card">
                    <div class="footer_card_item" *ngFor="let items of menu" 
                    [ngClass]="[!showMenu && (items.title == 'Talento') ? 'd-none' : 'd-block', !showMenu && (items.title == 'Actualidad') ? 'd-none' : 'd-block']" >
                        <p class="footer_card_item_title">{{items.title}}</p>
                        <ul class="footer_card_item_list">
                            <li  *ngFor="let item of items.sections" >
                                <span (click)="selectFooter(item)" style="cursor:pointer;">
                                    {{item.name}}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row gx-0 footer_rights">
        <div class="col-9 col-md-6 col-lg-3">
            <div class="footer_rights_text">
                <a href="#">Términos y Condiciones</a>
                <p>|</p>
                <a href="#">Política de privacidad</a>
            </div>
        </div>
    
        <div class="col-3 col-md-6 col-lg-7">
            <p class="footer_rights_text justify-content-end">©2023 Teggium.</p>
        </div>
    </div>
</section>