import { Component } from '@angular/core';

@Component({
  selector: 'app-tecnologia-page',
  templateUrl: './tecnologia-page.component.html',
  styleUrls: ['./tecnologia-page.component.scss']
})
export class TecnologiaPageComponent {
  ngAfterViewInit() {
    this.goToTop()
  }

  goToTop(){
    document.querySelector("#tecnologia")!.scrollIntoView();
  }
}
