import { Component, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicio-descubre',
  templateUrl: './inicio-descubre.component.html',
  styleUrls: ['./inicio-descubre.component.scss']
})
export class InicioDescubreComponent {
  public router = inject(Router)

  goToContactanos(){
    this.router.navigateByUrl('/contacto')
  }
  name = 'Video events';
  videoSource = "assets/img/video/vide-teggium.mp4";

@ViewChild('videoPlayer') videoplayer: any;
public startedPlay:boolean = false;
public show:boolean = false;
pauseVideo(videoplayer:any)
{
  // videoplayer.nativeElement.play();
  // this.startedPlay = true;
  // if(this.startedPlay == true)
  // {
     setTimeout(() => 
     {
      videoplayer.nativeElement.pause();
       if(videoplayer.nativeElement.paused)
      {
        this.show = !this.show;       
      } 
     }, 5000);
  // }
}

closebutton(videoplayer:any){
  this.show = !this.show; 
  videoplayer.nativeElement.play();
}
}
